<template>
<div id="workWarehouseC">
  <div style="width:100%; display:block;">
    <div class="card main" >
      <table class="table fulfillment-workwarehouse" style="width:100%;">``
        <tr>
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;vertical-align: middle;"  >
            담당자정보 
            <input type="button" value="삭제" style="float:right;max-width:50px;" @click="click({type:'DELETE_WORK_WAREHOUSE'})"/>
            <input type="button" value="수정" style="float:right;max-width:50px;margin-right:5px;" @click="click({type:'UPDATE_WORK_WAREHOUSE'})"/>
          </td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">IDX</td>
          <td style="width:25%"><input v-model="warehouseIdx" class="orderc-input" type="text" placeholder="IDX"/></td>
          <td style="width:25%;text-align: center;">나라</td>
          <td style="width:25%"><input v-model="warehouseCountry" ref="warehouseCountry" class="orderc-input" type="text" placeholder="나라" /></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">DESC</td>
          <td style="width:25%"><input v-model="warehouseDesc" class="orderc-input" type="text" placeholder="상세설명"/></td>
          <td style="width:25%;text-align: center;">ETC</td>
          <td style="width:25%"><input v-model="warehouseEtc" class="orderc-input" type="text" placeholder="기타"/></td>
        </tr>
        <tr>
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;"  >
            <select ref="warehouseQuoteC" :value="json.FILE_NAME" class="orderc-input" style="float:left;" @change="changeQuote()">
              <option value="">견적서 선택</option>
              <option value="GEEK9_물류비_국내_냉장3PL소분.xlsx">GEEK9_물류비_국내_냉장3PL소분</option>
              <option value="GEEK9_물류비_국내_보관_배송_각인.xlsx">GEEK9_물류비_국내_보관_배송_각인</option>
              <option value="GEEK9_물류비_국내와인.xlsx">GEEK9_물류비_국내와인</option>
              <option value="GEEK9_물류비_한국_물류창고.xlsx">GEEK9_물류비_한국_물류창고</option>
              <option value="GEEK9_물류비_한국2글로벌_소량.xlsx">GEEK9_물류비_한국2글로벌_소량</option>
              <option value="GEEK9_물류비_한국2홍콩.xlsx">GEEK9_물류비_한국2홍콩</option>
              <option value="GEEK9_물류비_한국2싱가포르_명의대행_보관_대량.xlsx">GEEK9_물류비_한국2싱가포르_명의대행_보관_대량</option>
              <option value="GEEK9_물류비_한국2싱가포르_항공수출_식품_냉동.xlsx">GEEK9_물류비_한국2싱가포르_항공수출_식품_냉동</option>
              <option value="GEEK9_물류비_한국2싱가포르_냉동냉장.xlsx">GEEK9_물류비_한국2싱가포르_냉동냉장.xlsx</option>
              <option value="GEEK9_물류비_한국2싱가포르_전자제품.xlsx">GEEK9_물류비_한국2싱가포르_전자제품</option>
              <option value="GEEK9_물류비_한국2미국_미국보관_소량.xlsx">GEEK9_물류비_한국2미국_미국보관_소량</option>
              <option value="GEEK9_물류비_중국2한국.xlsx">GEEK9_물류비_중국2한국</option>
              <option value="GEEK9_물류비_중국2홍콩_대량수출.xlsx">GEEK9_물류비_중국2홍콩_대량수출.xlsx</option>
              <option value="GEEK9_물류비_중국로컬3PL_냉동_상해.xlsx">GEEK9_물류비_중국로컬3PL_냉동_상해</option>
              <option value="GEEK9_물류비_중국2미국일본_화학제품.xlsx">GEEK9_물류비_중국2미국일본_화학제품</option>
              <option value="GEEK9_물류비_중국천진_부산_선박.xlsx">GEEK9_물류비_중국천진_부산_선박</option>
              <option value="GEEK9_물류비_3PL_미국2한국.xlsx">GEEK9_물류비_3PL_미국2한국</option>
              <option value="GEEK9_물류비_미국3PL.xlsx">GEEK9_물류비_미국3PL</option>
              <option value="GEEK9_물류비_미국로컬3PL.xlsx">GEEK9_물류비_미국로컬3PL</option>
              <option value="GEEK9_물류비_미국2한국_3PL_건강기능식품.xlsx">GEEK9_물류비_미국2한국_3PL_건강기능식품</option>
              <option value="GEEK9_물류비_미국2한국_와인_배송_CA보관.xlsx">GEEK9_물류비_미국2한국_와인_배송_CA보관</option>
              <option value="GEEK9_물류비_미국2한국_와인_배송_뉴저지보관2.xlsx">GEEK9_물류비_미국2한국_와인_배송_뉴저지보관</option>
              <option value="GEEK9_물류비_미국내배송_UPS.xlsx">GEEK9_물류비_미국내배송_UPS</option>
              <option value="GEEK9_물류비_싱가포르_3PL_냉동.xlsx">GEEK9_물류비_싱가포르_3PL_냉동</option>
              <option value="GEEK9_물류비_싱가포르_로컬_3PL_상온.xlsx">GEEK9_물류비_싱가포르_로컬_3PL_상온</option>
              <option value="GEEK9_물류비_싱가포르_로컬_3PL_상온2.xlsx">GEEK9_물류비_싱가포르_로컬_3PL_상온2</option>
              <option value="GEEK9_물류비_싱가포르_보관_로컬배송.xlsx">GEEK9_물류비_싱가포르_보관_로컬배송</option>
              <option value="GEEK9_물류비_싱가포르3PL_로컬배송.xlsx">GEEK9_물류비_싱가포르3PL_로컬배송</option>
              <option value="GEEK9_물류비_홍콩2한국.xlsx">GEEK9_물류비_홍콩2한국</option>
              <option value="GEEK9_물류비_홍콩2홍콩.xlsx">GEEK9_물류비_홍콩2홍콩</option>
              <option value="GEEK9_물류비_홍콩2글로벌_배송비별도1.xlsx">GEEK9_물류비_홍콩2글로벌_배송비별도1</option>
              <option value="GEEK9_물류비_홍콩2글로벌_배송비별도2.xlsx">GEEK9_물류비_홍콩2글로벌_배송비별도2</option>
              <option value="GEEK9_물류비_한국2홍콩_전자상거래_명의대행.xlsx">GEEK9_물류비_한국2홍콩_전자상거래_명의대행</option>
              <option value="GEEK9_물류비_한국2홍콩_소량.xlsx">GEEK9_물류비_한국2홍콩_소량</option>
            </select>
            상세내용
          </td>
        </tr>
      </table>
    </div> 
  </div>
</div>  
</template>
<script>
export default {
  mounted() {
    this.warehouseEtc = this.json.ETC
    this.warehouseDesc = this.json.DESC
    this.warehouseCountry = this.json.COUNTRY
    this.warehouseFileName = this.json.FILE_NAME
    this.warehouseRegDate = this.json.REG_DATE
  },
  data() { 
    return { 
      warehouseEtc:'',
      warehouseDesc:'',
      warehouseCountry:'',
      warehouseFileName:'',
      warehouseRegDate:''
    }
  },
  props: {
    json: {},
  },
  methods: {
    click(param) {
      const type = param.type
      const formData = new FormData()
      let workType = undefined
      if(type === 'UPDATE_WORK_WAREHOUSE') {
        workType = '수정'
        const etc = this.warehouseEtc
        const desc = this.warehouseDesc
        const country = this.warehouseCountry
        const fileName = this.fileName
        
        formData.append('type', type)
        formData.append('idx', this.json.IDX)
        formData.append('etc', etc)
        formData.append('desc', desc)
        formData.append('country', country)
        formData.append('fileName', fileName)
        
      } else if(type === 'DELETE_WORK_WAREHOUSE') {
        workType = '삭제'
        formData.append('type', type)
        formData.append('idx', this.json.IDX)
      }
      if(confirm(workType + "할까요?")) {
        this.execute({type:type, json:formData, workType:workType})
      }
    },
    async execute(param) {
      const type = param.type
      const json = param.json
      const workType = param.workType
      await this.api.axiosGql(type, json)
      .then( async (res) => {
        if(res.data === null) alert('다시 시도해주세요.')  
        else {
          this.$emit("search", {type:'SELECT_WORK_WAREHOUSE', page:1})
          alert(workType + ' 되었습니다.')
        }
      }).catch( (err) => {
        alert('다시 시도해주세요.')
        console.log(err)
      })
    },
    async changeQuote() {
      const fileName = this.$refs.warehouseQuoteC.value
      if(confirm("등록할까요?" ) === true) {
        const type = 'UPDATE_WORK_WAREHOUSE'
        await this.api.axiosGql(type, {type:type, idx:this.json.IDX, 'fileName':fileName})
        .then( async (res) => {
          if(res.data === null) alert('다시 시도해주세요.')  
          else {
            this.$emit("search", {type:'SELECT_WORK_WAREHOUSE', page:1})
            alert('변경 되었습니다.')
          }
        }).catch( (err) => {
          alert('다시 시도해주세요.')
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>
.admin-workmgr {
  width:100%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
.admin-volume-workmgr {
  padding-top:5px;
  padding-bottom:5px;
  margin-right:5px;
  text-align:center;
}
.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.fulfillment-workmgr-value {
  width:30%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
td:nth-child(even){
  width:25%;
  border: 1px dotted #ffa07a; 
  padding-top:5px;
  padding-bottom:5px;
}
td:nth-child(odd){
  text-align: center;
  width:25%;
  border: 1px dotted #ffa07a; 
  font-weight:bold;
}
tr {
  height:30px;
}
table {
  border-collapse: collapse;
}
table tr:first-child td {
  border-top: 0;
}
table tr td:first-child {
  border-left: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:last-child {
  border-right: 0;
}
.orderc-input {
  text-align: center;
  max-width:fit-content;
  padding-top:5px;
  padding-bottom:5px;
}
</style>