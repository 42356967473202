<template>
  <div>
    <div style="width:100%; display:block;">
      <table style="width:100%">
        <tbody>
          <tr style="height: 30px;">
            <td class="text-left">수정시간</td> <td class="qna_sub_style">{{this.addDelimeter(profitModTime)}}</td>
            <td class="text-left">평단가</td> 
            <td class="qna_sub_style" >
              <input ref='price' type="number" :value="price" style="width:50px;"/>원
            </td>
          </tr>
          <tr style="height: 30px;">
            <td class="text-left">보유량</td> <td class="qna_sub_style">
              <input ref='amount' type="number" :value="amount" style="width:50px;"/>주
            </td>
            <td class="text-left">수익률</td> 
            <td class="qna_sub_style" >
              <input ref="profit_rate" step='0.01' type="number" :value="profitRate" style="width:50px;"/>%
            </td>
          </tr>            
          <tr style="height: 30px;">
            <td class="text-left">목표매수가</td> 
            <td class="qna_sub_style">
              <input ref="nextBuyPrice" type="number" :value="nextBuyPrice" style="width:50px;"/>원
            </td>
            <td class="text-left">목표매도가</td> <td class="qna_sub_style">
              <input ref="nextSellPrice" type="number" :value="nextSellPrice" style="width:50px;"/>원
            </td>
          </tr>
          <tr style="height: 30px;">
            <td class="text-left">종목코드</td> <td class="qna_sub_style" id="selectedCode">{{code}}</td>
            <td class="text-left" v-if="isSell === true" style="color:red">최근 {{this.recentPrice.toLocaleString()}}원({{((this.presPrice - this.recentPrice)/this.recentPrice * 100).toFixed(2).toLocaleString()}}%) {{this.insertAmount}}주 매도일</td> 
            <td class="text-left" v-else style="color:blue">최근 {{this.recentPrice.toLocaleString()}}원({{((this.presPrice - this.recentPrice)/this.recentPrice * 100).toFixed(2).toLocaleString()}}%) {{this.insertAmount}}주 매수일</td> 
            <td class="qna_sub_style">{{this.addDelimeter(date)}}</td>
          </tr>

          <tr>
            <td style="width:100%" colspan="6">
              <textarea style='width:98%; padding:5px;' ref="content" :value="content"/>
            </td>
          </tr>

          <tr rowspan="4" style="height: 50px;">
            <td colspan="2" class="text-left">
              <span style="margin:10px;" class="link" @click="copyClipboard('NAME')" >종목명</span>
              <span style="margin:10px;" class="link" @click="copyClipboard('CODE')" >종목코드</span>
              <span style="margin:10px;" class="link" @click="openUrl()" >차트</span>
            </td>
            <td colspan="2" class="text-right">
              <span style="margin:10px;" class="link" @click="modify('SELECT_STOCK_TRADE2')">대기여부</span>
              <span style="margin:10px;" class="link" @click="modify('refresh')">갱신</span>
              <span style="margin:10px;" class="link" @click="modify('next')">목표가</span>
              <span style="margin:10px;" class="link" @click="modify('modify')">수정</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table id="table_possess_list" style="width:98%; margin:10px;">
        <tbody>
          <tr style="text-align: center; background-color: antiquewhite;" >
            <td>증권사(보유)</td>
            <td>목표매수/목표매도</td>
            <td style="width:30%">최근매매</td>
            <td style="width:20%">수익률</td>
          </tr>
          <tr v-for="(stock, index) in possessList" :key="index" :style="stock.IS_FIRST ? 'font-weight:bolder;font-style:italic;background-color:lightgoldenrodyellow' : ''" >
            <td>{{stock.BROKER}}<br>({{stock.AMOUNT}}주)</td>
            <td>{{Number(stock.NEXT_BUY_PRICE).toLocaleString()}} / {{Number(stock.NEXT_SELL_PRICE).toLocaleString()}}</td> 
            <td v-if="stock.IS_SELL === true" style="color:red;">{{stock.DATE}} {{Number(stock.RECENT_PRICE).toLocaleString()}}원<br>{{stock.INSERT_AMOUNT}}주 매도</td>
            <td v-else style="color:blue;">{{stock.DATE}} {{Number(stock.RECENT_PRICE).toLocaleString()}}원<br>{{stock.INSERT_AMOUNT}}주 매수</td>
            <td v-if="String(stock.PROFIT_RATE).indexOf('-') === -1" style="color:red;">{{Number(stock.PROFIT_RATE).toFixed(2)}}%</td>
            <td v-else style="color:blue;">{{Number(stock.PROFIT_RATE).toFixed(2)}}%</td>
          </tr>
          <tr>
            <td colspan="2">
              <input type="button" @click="modify('prevOpen')" style="border: 1;padding: 5px; font-weight: bold;" value="PREV">
            </td>
            <td colspan="2">
              <input type="button" @click="modify('nextOpen')" style="border: 1;padding: 5px; font-weight: bold;" value="NEXT">
            </td>
          </tr>
        </tbody>
      </table>
      <table id="table_detail" style="width:98%; margin:10px;">
        <tbody>
          <tr style="text-align: center; background-color: antiquewhite;" >
            <td colspan="4" style="height:40px;">DETAIL</td>
          </tr>
          <tr>
            <td class="text-left" >등락</td>
            <td ref="delta" >-</td>
            <td class="text-left" >거래량</td>
            <td ref="tradeAmount" >-</td>
          </tr>
          <tr>
            <td class="text-left" >50일평균</td>
            <td ref="avg50" >-</td>
            <td class="text-left" >200일평균</td>
            <td ref="avg200" >-</td>
          </tr>
          <tr>
            <td class="text-left" >시가</td>
            <td ref="startPrice" >-</td>
            <td class="text-left" >고가</td>
            <td ref="highPrice" >-</td>
          </tr>
          <tr>
            <td class="text-left" >저가</td>
            <td ref="lowPrice" >-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
</template>
<script>
export default {
  props: {
    idx: Number,
    amount: Number,
    broker: String,
    code: String,
    date: String,
    name: String,
    content: String,
    insertAmount: Number,
    nextBuyPrice : Number,
    nextSellPrice : Number,
    price : Number,
    presPrice : Number,
    recentPrice : Number,
    profitModTime: String,
    profitRate: Number,
    isSell: Boolean
  },
  data() { 
    return { 
      possessList: Array()
    }; 
  },
  unmounted() {
    this.emitter.off('stockRefresh', this.refresh)
  },
  mounted() {
    this.emitter.on('stockRefresh', this.refresh)
  },
  methods: {
    refresh(param) {
      if(this.idx === param.idx) this.modify('refresh')
    },
    async modify(type) {
      if(type === 'prevOpen') {
        let presIdx = Number(this.idx)
        if(presIdx !== 0) this.$emit('collapse', {idx:Number(this.idx) - 1, code:''})
        else this.$notify({type:'warning', title:'알림', text:'이전 종목이 없습니다.'})
      } else if(type === 'nextOpen') {
        this.$emit('collapse', {idx:Number(this.idx) + 1, code:''})
      } else if(type === 'next') {
        await this.api.calcNextPrice({PRICE:this.recentPrice, BROKER:this.broker}, this.recentPrice)
        .then(async (result) => {
          this.$refs.nextBuyPrice.value = Number(result.NEXT_BUY_PRICE)
          this.$refs.nextSellPrice.value = Number(result.NEXT_SELL_PRICE)
        })
      } else if(type === "modify") {
        let amount = Number(this.$refs.amount.value)
        let nextBuyPrice = Number(this.$refs.nextBuyPrice.value)
        let nextSellPrice = Number(this.$refs.nextSellPrice.value)
        let price = Number(this.$refs.price.value)
        let content = this.$refs.content.value
        this.$emit(type, {type:type, code: this.code, content:content, amount: amount, broker: this.broker, nextBuyPrice: nextBuyPrice, nextSellPrice: nextSellPrice, price: price})
      } else if(type === 'refresh') {

        // YAHOO 정보 하지 않고 네이버에서 가지고 오는 정보로만 한다 @20221111
        
        // await this.api.axiosGql('YAHOO_SELECT_STOCK_BY_CODE', {type: 'YAHOO_SELECT_STOCK_BY_CODE', code: this.code})
        // .then( async (result) => {
        //   let json = result.data
        //   let ms = Number(Date.parse(Date())) / 1000
        //   let presTime = Number(this.api.getDate(ms, 'YYYYMMDD').substring(4,8))
        //   if(json.error === undefined && json.presPrice !== undefined && presTime >= 930) {
        //     let presPrice = Number(json.presPrice)
        //     // let deltaPrice = Number(json.delta)
        //     let deltaRate = Number(json.delta).toFixed(2) + '%'
        //     let tradeAmount = Number(json.tradeAmount)
        //     // let status = r[4]
        //     // if(status !== '') this.$notify(status)
        //     let newProfitRate = Number((presPrice-this.price)/this.price*100).toFixed(2)
        //     let upDn = ''
        //     if(deltaRate.indexOf('-') !== -1) {
        //       this.$refs.delta.style.color = 'blue'
        //       upDn = '▼'
        //     } else {
        //       this.$refs.delta.style.color = 'red'
        //       upDn = '▲'
        //     }
        //     this.$refs.delta.innerHTML = upDn + deltaRate
        //     this.$refs.tradeAmount.innerHTML = Number(tradeAmount).toLocaleString() + '주'
        //     this.$refs.avg200.innerHTML = Number(json.avg200).toLocaleString() + '원'
        //     this.$refs.avg50.innerHTML = Number(json.avg50).toLocaleString() + '원'
        //     this.$refs.startPrice.innerHTML = Number(json.startPrice).toLocaleString() + '원'
        //     this.$refs.highPrice.innerHTML = Number(json.highPrice).toLocaleString() + '원'
        //     this.$refs.lowPrice.innerHTML = Number(json.lowPrice).toLocaleString() + '원'
        //     this.$emit('setRecentInfo', {idx:this.idx, presPrice:presPrice, profitRate: newProfitRate})
        
        //   } else 
        await this.api.axiosGql(type, {type: type, code: this.code})
        .then( async (result) => {
          let r = result.data
          // let presPrice = Number(r[0])
          let deltaPrice = r[1]
          let deltaRate = r[2] + '%'
          let tradeAmount = r[3]
          let status = r[4]
          
          if(status !== '') this.$notify(status)
          // let newProfitRate = Number((presPrice-this.price)/this.price*100).toFixed(2)
          let upDn = ''
          let deltaTag = this.$refs.delta
          
          if(deltaRate.indexOf('-') !== -1) {
            if(deltaTag !== null) deltaTag.style.color = 'blue'
            upDn = '▼'
          } else if(deltaRate.indexOf('+') !== -1) {
            if(deltaTag !== null) deltaTag.style.color = 'red'
            upDn = '▲'
          }
          
          if(deltaTag !== null) deltaTag.innerHTML = upDn + deltaPrice + '원 (' + deltaRate + ')'
          if(this.$refs.tradeAmount !== null) this.$refs.tradeAmount.innerHTML = Number(tradeAmount).toLocaleString() + '주'
          
          // this.$emit('setRecentInfo', {idx:this.idx, presPrice:presPrice, profitRate: newProfitRate})
        })
        
        await this.api.axiosGql('SELECT_STOCK_BY_CODE', {type:'SELECT_STOCK_BY_CODE', code: this.code, broker:''})
        .then( async (result) => {
          let origin = result.data  
          if(origin !== undefined && origin.length !== 0) {
            await this.getRecentTrade(origin)
            .then( async (tmpResult) => {
              this.possessList = []
              this.possessList = tmpResult
            })
          }
        })
      } else if(type === 'SELECT_STOCK_TRADE2') {
        await this.api.axiosGql(type, {type:'SELECT_STOCK_TRADE2', code: this.code})
        .then( async (result) => {
            let json = result.data
            if(json === undefined || json.length === 0) {
              this.$notify('매매 대기 리스트에 없습니다.')
            } else if(json.length === 1) {
              json = json[0]
              let amount = json.AMOUNT
              let broker = json.BROKER
              let name = json.NAME
              let price = Number(json.PRICE).toLocaleString()
              let isSell
              json.IS_SELL === true ? isSell='매도' : isSell='매수'
              this.$notify('[' + name + ' - ' + broker + ']\n' +price + '원에 ' + amount + '주 ' + isSell + ' 대기 중입니다.')
            } else {
              this.$notify('2개 이상의 주문이 매매 대기 중입니다.')
            }
          }
        )
      }
    },
    async getRecentTrade(origin) {
      let val = -1
      for(let i=0; i<origin.length; i++) {
        let stock = origin[i]
        let code = stock.CODE
        let broker = stock.BROKER
        
        await this.api.axiosGql('SELECT_TOP_STOCK_BY_CODE', {type:'SELECT_TOP_STOCK_BY_CODE', code: code, broker: broker})
        .then( async (result2) => {
          let topStock = result2.data
          if(topStock.length > 0) {
            stock.DATE = topStock[0].DATE
            let cmprVal = Number(stock.DATE)
            if(i === 0 && cmprVal > val) {
              val = cmprVal
              stock.IS_FIRST = true
            } else if(i > 0 && cmprVal > val) { 
              val = cmprVal
              stock.IS_FIRST = true
              origin[i-1].IS_FIRST = false
              origin[0].IS_FIRST = false
            } else stock.IS_FIRST = false

            stock.IS_SELL = Boolean(topStock[0].IS_SELL)
            stock.INSERT_AMOUNT = topStock[0].AMOUNT
            stock.RECENT_PRICE = topStock[0].PRICE
            origin[i] = stock
          }
        })
      }
      return origin
    },
    addDelimeter(date) {
      if(date.length === 14) {
        return date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8) + " " + date.substring(8, 10) + ":" + date.substring(10, 12) + ":" + date.substring(12, 14)
      } else if(date.length === 8) {
        return date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
      }
    },
    copyClipboard(type) {
      let text
      if(type === 'CODE') {
        text = this.code
      } else if(type === 'NAME') {
        text = this.name
      }
      navigator.clipboard.writeText(text)
      .then( () => {
        this.$notify("복사되었습니다 - " + text)
      })
      .catch( (err) => console.log(err) )
    },
    openUrl() {
      window.open('https://m.stock.naver.com/domestic/stock/' + this.code +'/total')
    }
  }
}
</script>
<style scoped>
.text-left {
  text-align: left;
  font-weight: bold;
}
.text-right {
  text-align: right;
}
.qna_sub_style {
  width: 25%;
  border-right: 1px dotted gray;
}

textarea {
  height: auto;
  overflow:visible;
  resize: vertical;
}

#table_detail tr td, #table_possess_list tr td {
  height:40px; 
  width:25%
}

</style>