<template>
  <table class="table fulfillment receive-estimate" style="width:100%; ">
    <tr style="height:40px;text-align: center;background-color:#fff7ed;">
      <td colspan="4" style="width:100%">
        받은견적 #{{ idx+1 }}
        <input type="button" @click='remove(idx)' value="삭제" style="max-width:50px;float:right;">
      </td>

    </tr>
    <tr style="height:40px;text-align: center;">
      <td style="width:25%;text-align: center;">제안업체</td>
      <td style="width:25%"><input class="admin-contract" type="text" placeholder="견적을 제공한 업체명"/></td>
      <td style="width:25%;text-align: center;">담당자</td>
      <td style="width:25%"><input class="admin-contract" type="url" placeholder="견적 제공한 업체의 담당자명"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td style="width:25%;text-align: center;">연락처</td>
      <td style="width:25%"><input class="admin-contract" type="text" placeholder="담당자 연락처"/></td>
      <td style="width:25%;text-align: center;">이메일</td>
      <td style="width:25%"><input class="admin-contract" type="url" placeholder="담당자 이메일"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td colspan="4" style="width:100%"><textarea class="admin-contract" placeholder="GEEK9가 담당자에게 받은 견적" style="width:100%;background-color:transparent;" /></td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    idx: Number,
  },
  data() { 
    return { 
    } 
  },
  methods: {
    remove() {
      
      this.$emit("remove", this.idx)
    },
  }
}
</script>

<style scoped>
td > textarea {
  width: 100%;
  height: 200px;
  resize: none;
}
tr > td:nth-child(odd) {
  font-weight: bolder;
}
.admin-contract {
  width:100%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
</style>
