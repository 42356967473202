<template>
  <div class="div_title2" >
    <span style="color: #ff7f50;">Recommend</span> 
    <span class="div_title2">
      <div class="div_center">
        <input type="checkbox" v-model="recmUseYn" id="recmUseYn" style="vertical-align: middle;"  />
        <label for="recmUseYn">useYn</label>
      </div>
      &nbsp;
      &nbsp;
      <input type="text" v-on:keyup.enter="click({type:'SELECT_NOTICE'})" v-model="keyword" placeholder="검색어" style="padding:5px;max-width:80px;">
      &nbsp;
      <Button label="등록" @click="openRecmDialog" raised size="small" class="qa-fair-btn2"/>      
      &nbsp;
      <Button label="조회" @click="click({type:'SELECT_NOTICE'})" raised size="small" class="qa-fair-btn2"/>
    </span>
  </div>
  <Divider/>
  <div class="card main">
    <div v-if="recommendList === undefined || recommendList.length === 0" class="no-result" > 조회를 해주세요.</div>
    <div v-else>
      <DataTable v-model:expandedRows="expandedRows" :value="recommendList" dataKey="SEQ" @rowExpand="onRowExpand" stripedRows editMode="cell">
        <Column expander />
        <Column field="" header="키워드" >
          <template #body="slotProps">
            <span>{{ slotProps.data.TITLE }}</span>
          </template>
        </Column>
        <Column field="" header="URL" >
        <template #body="slotProps">
          <span>{{ slotProps.data.CONTENT }}</span>
        </template>
        </Column>
        <template #expansion="slotProps" >
          <table class="dynamic-table" style="margin-bottom:0px;">
            <tr style="height:100px;">
              <td>
                <table class="order_sub_table" style="table-layout:fixed;">
                  <tbody style="text-align: left;">
                    <tr tr style="height:30px;">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>SEQ</div>
                          <div>{{slotProps.data.SEQ}}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>등록일</div>
                          <div>{{this.api.getDateByFormat(slotProps.data.REG_DATE, 'YYYY.MM.DD')}}</div>
                        </div>
                      </td>
                    </tr>
                    <tr style="height:30px;">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>ID</div>
                          <div>{{slotProps.data.ID}}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>사용여부</div>
                          <div>
                            <select ref="recmUseYn" v-model="slotProps.data.USE_YN" @change="click({type:'change', seq:Number(slotProps.data.SEQ), priority:0})" style="padding:5px;">
                              <option value = "true">사용함</option>
                              <option value = "false">사용안함</option>
                            </select>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
          <DataTable :value="[slotProps.data]" editMode="cell" >
            <Column style="width:100%;">
              <template #body="{ data }">
                <table class="dynamic-table" style="margin-bottom:0px;">
                  <tr style="height:100px;">
                    <td>
                      <table class="order_sub_table" style="table-layout:fixed;">
                        <tbody style="text-align: left;">
                          <tr tr style="height:30px;">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>키워드</div>
                                <div>{{data.TITLE}}</div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>URL</div>
                                <div><a :href="data.CONTENT" target="_blank">{{data.CONTENT}}</a> </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </template>
              <template #editor="{ data }">
                <div class="div_title2">
                  <div class="container">
                    <Textarea v-model="data.TITLE" autofocus fluid style="width:100%;" autoResize />
                    <Textarea v-model="data.CONTENT" autofocus fluid style="width:100%;" autoResize />
                  </div>
                  <span>
                    <Button text size="small" @click="click({type:'UPDATE_NOTICE', seq:Number(data.SEQ), title:data.TITLE, content:data.CONTENT, priority:0,  useYn:data.USE_YN})" icon="pi pi-pencil" severity="info" /><br>
                    <Button text size="small" @click="click({type:'DELETE_NOTICE', seq:Number(data.SEQ)})" icon="pi pi-times" severity="danger"  /> 
                  </span>
                </div>
              </template>
            </Column>
          </DataTable>
          <div class="div_title">
            <Button label="삭제" @click="click({type:'DELETE_NOTICE', seq:Number(slotProps.data.SEQ)})" raised size="small" />
          </div>
        </template>
      </DataTable>
      <div class="div_center">
        <label @click="paging('prev')" class="link">이전</label>
        <span style="margin:20px">{{page}}</span>
        <label @click="paging('next')" class="link">다음</label>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="recmVisible" modal header="등록" style="min-width:300px;" >
    <FloatLabel class="flex-item input-margin" >
      <InputText id="recmKeyword"  v-model="recmKeyword" v-on:keyup.enter="click({type:'INSERT_NOTICE', priority:0,  useYn:true})" style="width:100%;"/>
      <label for="recmKeyword" >키워드</label>
    </FloatLabel>
    <FloatLabel class="flex-item input-margin" >
      <InputText id="recmUrl"  v-model="recmUrl" v-on:keyup.enter="click({type:'INSERT_NOTICE', priority:0,  useYn:true})" style="width:100%;"/>
      <label for="recmUrl" >URL</label>
    </FloatLabel>
    <Divider/>
    <Button label="등록" @click="click({type:'INSERT_NOTICE', priority:0,  useYn:true})" raised  />      
  </Dialog>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const recmVisible = ref(false) 
    function openRecmDialog () {
      recmVisible.value = true
    }
    function closeRecmDialog () {
      recmVisible.value = false
    }
    return {
      recmVisible, openRecmDialog, closeRecmDialog
    }
  },
  data() {
    return {
      recmUrl:undefined,
      recmKeyword:undefined,
      recmUseYn:true,
      page:1,
      keyword:'',
      recommendList:[],
      query:undefined,
      expandedRows:[]
    }
  },
  methods: {
    onRowExpand (event) {
      const json = event.data
      const seq = json.SEQ
      this.expandedRows = {[seq]:true}
    },
    async click(param) {
      const type = param.type
      if(type === 'SELECT_NOTICE') {
        this.emitter.emit('dialog', {flag:true, title:'검색중.'})
        await this.getRecommend({type:type, keyword:this.keyword, page:this.page, useYn:this.recmUseYn, noticeType:'chatbot-recm' })
        .then( async (res) => {
          if(res.status === 200) this.recommendList = res.data
        })
        .catch( (err) => {
        console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'UPDATE_NOTICE') {
        if(confirm("수정할까요?")) {
          await this.api.axiosGql(type, param)
          .then( async (result) => {
            if(result !== null && result !== undefined) {
              if(Number(result.data.SEQ) === param.seq) {
                this.click({type:'SELECT_NOTICE'})
                alert('수정되었습니다.')
              }
            }
          })
          .catch( (err) => {
            console.log(err)
          })
        }
      } else if(type === 'INSERT_NOTICE') {
        if(confirm("등록할까요?")) {
          if(this.recmKeyword === undefined || this.recmKeyword === '') alert('키워드가 누락되었습니다.')
          else if(this.recmUrl === undefined || this.recmUrl === '') alert('URL이 누락되었습니다.')
          else {
            param.title = this.recmKeyword
            param.content = this.recmUrl
            param.noticeType = 'chatbot-recm'
            param.id = this.api.getCookies('mail')
            await this.api.axiosGql(type, param)
            .then( async (result) => {
              if(result === null || result === undefined) alert('다시 시도해주세요.')
              else {
                this.recmKeyword = undefined
                this.recmUrl = undefined
                this.click({type:'SELECT_NOTICE'})
                alert('등록되었습니다.')
              }
              
            })
            .catch( (err) => {
              console.log(err)
              alert('다시 시도해주세요.')
            })
            .finally( () => {
              this.closeRecmDialog()
            })
          }
        }
      } else if(type === 'DELETE_NOTICE') {
        if(confirm("삭제할까요?")) {
          await this.api.axiosGql(type, {type:type, seq:param.seq} )
          .then( async (result) => {
            if(Number(result.data) === param.seq) {
              this.click({type:'SELECT_NOTICE'})
              alert('삭제되었습니다.')
            } else alert('다시 시도해주세요')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요')
          })
        }
      } else if(type === 'change') {
        param.type = 'UPDATE_NOTICE'
        param.useYn = this.$refs.recmUseYn.value
        this.click(param)
      }
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1

      await this.getRecommend({type:'SELECT_NOTICE', keyword:this.keyword, page:this.page, useYn:this.recmUseYn, noticeType:'chatbot-recm' })
      .then( async (res) => {
        if(res.data.length === 0) {
        this.page -= 1
        alert('마지막 페이지입니다.')
        } else this.recommendList = res.data
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    async getRecommend(param) {
      const type = param.type
      return this.api.axiosGql(type, param)
    },
  }
}
</script>
<style scoped>
.qa-fair-btn {
  min-width:100px;
}
.qa-fair-btn2 {
  min-width:50px;
}
</style>