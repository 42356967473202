<template>
  <div>
    <div id="nav-admin-icon" v-on:click="clickMenu" >
      <span/><span/><span/><span/><span/>
    </div>
    <nav id="admin-menu">
      <table class="table" style="width:100%;">
        <tr>
          <th>ID</th>
          <th>코드</th>
          <th>종목명</th>
          <th>매매가</th>
          <th>수량</th>
          <th>매매</th>
          <th>증권사</th>
        </tr>
        <tr>
          <td class="input_width"></td>
          <td class="input_width"><input type="text" ref="code" @keyup="click('codeKeyup')"></td>
          <td class="input_width"><input type="text" ref="name"></td>
          <td class="input_width"><input type="number" ref="price"></td>
          <td class="input_width"><input type="number" ref="amount" @keyup="click('amountKeyup')"></td>
          <td class="input_width">
            <select ref="isSell">  
              <option value = "0">매수</option>
              <option value = "1">매도</option>
            </select>
          </td>
          <td class="input_width">
            <select ref="broker">  
              <option value = "삼성증권">삼성</option>
              <option value = "미래에셋증권">미래</option>
              <option value = "한국투자증권">한국</option>
            </select>
          </td>
        </tr>        
        <tr v-for="(stock, index) in tradeList" :key="index" >
          <td class="link" v-on:click="remove(stock.ID)">{{stock.ID}}</td>
          <td class="link" v-on:click="view(stock.CODE)">{{stock.CODE}}</td>
          <td class="link" v-on:click="view(stock.CODE)">{{stock.NAME}}</td>
          <td class="link" v-on:click="modify('price', {name:stock.NAME, code:stock.CODE, broker:stock.BROKER, price:stock.PRICE, amount:stock.AMOUNT})">{{Number(stock.PRICE).toLocaleString()}}원</td>
          <td class="link" v-on:click="modify('amount', {name:stock.NAME, code:stock.CODE, broker:stock.BROKER, price:stock.PRICE, amount:stock.AMOUNT})">{{Number(stock.AMOUNT)}}주</td>
          <td class="link" v-on:click="modify('UPSERT_STOCK_TRADE', {name:stock.NAME, code:stock.CODE, broker:stock.BROKER, price:stock.PRICE, amount:stock.AMOUNT, isSell:stock.IS_SELL})" v-if="stock.IS_SELL === true" style="color:red" >매도</td>
          <td class="link" v-on:click="modify('UPSERT_STOCK_TRADE', {name:stock.NAME, code:stock.CODE, broker:stock.BROKER, price:stock.PRICE, amount:stock.AMOUNT, isSell:stock.IS_SELL})" v-else style="color:blue" >매수</td>
          <td>{{stock.BROKER}}</td>
        </tr>
      </table>
      <table class="table" style="width:100%;">
        <tr>
          <td colspan="1"><input style="margin-top: 5px;" type="button" v-on:click="paging('prev')" value="이전"></td>
          <td colspan="1"><input style="margin-top: 5px;" type="button" v-on:click="paging('next')" value="다음"></td>
          <td colspan="1"><input style="margin-top: 5px;" type="button" v-on:click="click('SELECT_STOCK_TRADE')" value="조회"></td>
          <td colspan="1"><input style="margin-top: 5px;" type="button" v-on:click="click('INSERT_STOCK_TRADE')" value="등록"></td>
          <!-- <td colspan="1"><input style="margin-top: 5px;" type="button" v-on:click="click('UPSERT_STOCK_TRADE')" value="체결"></td> -->
        </tr> 
      </table>
    </nav>
  </div>
</template>
<script>

export default {
  data() { 
    return { 
      tradeList: Array(),
      tradePage: 1,
    }; 
  },
  methods: {
    view(code) {
      this.$emit('view', {code:code})
    },
    async modify(type, param) {
      let code = param.code
      let broker = param.broker
      let price = param.price
      let amount = param.amount
      let isSell = param.isSell
      let name = param.name

      let newPrice
      let newAmount
      if(type === 'price') {
        type = '목표 매매가'
        newPrice = Number(prompt('새로운 목표 매매가를 입력하세요', price))
        if(newPrice === 0) return
        else if(price === newPrice) {
          this.$notify('동일한 목표 매매가입니다')
          return
        } else {
          if(confirm(code + ' 종목의 ' + type + ' 정보를 수정하시겠습니까?')) {
            this.updateTrade(code, broker, newPrice, newAmount)
          }
        }
      } else if(type === 'amount') {
        type = '매매 수량'
        newAmount = Number(prompt('새로운 매매 수량을 입력하세요', amount))

        if(newAmount === 0 || isNaN(newAmount) === true) {
          this.$notify('잘못된 값입니다(' + newAmount + ')')
        } else if(amount === newAmount) {
          this.$notify('동일한 매매 수량입니다')
        } else if(confirm(code + ' 종목의 ' + type + ' 정보를 수정하시겠습니까?')) {
          this.updateTrade(code, broker, newPrice, newAmount)
        }
      } else if(type === 'UPSERT_STOCK_TRADE') {
        let trade
        isSell === true ? trade = '매도' : trade = '매수'
        
        newPrice = Number(prompt(code + ' 종목을 ' + '아래의 가격으로 '+ trade + ' 처리 하겠습니까?', price))
        
        if(newPrice === 0) {
          this.$notify('취소되었습니다')
        } else if(isNaN(newPrice) === true) {
          this.$notify('잘못된 값입니다(' + newPrice + ')')
        } else {
          let ms = Number(Date.parse(Date())) / 1000
          let insertDate = this.api.getDate(ms, 'YYYYMMDD')

          await this.api.axiosGql('SELECT_STOCK_BY_CODE', {type:'SELECT_STOCK_BY_CODE', code: code, broker: broker})
          .then( async (possessResult) => {
            let possessStock = possessResult.data
            let p = {type:'INSERT_STOCK',price: newPrice, amount: amount, code: code, name: name, broker: broker, isSell:isSell, date: insertDate}
            if( possessStock.length === 0) {
              await this.api.axiosGql('INSERT_STOCK', p)
              .then( async (result) => {
                if(result === null || result === undefined) {
                  this.$notify('등록이 되지 않았습니다')
                } else {
                  await this.api.calcNextPrice(result.insertStock, -1).then(async (result) => {
                    if(result === null || result === undefined) {
                      this.$notify('등록이 되지 않았습니다')
                    } else {
                      await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
                      .then( async () => {
                        this.$notify('등록이 되었습니다')
                      }).catch( (err) => {
                        console.log(err)
                        this.$notify('등록이 되지 않았습니다')
                      })
                    }
                  })
                }
              })
            } else {
              await this.api.axiosGql('INSERT_STOCK', p)
              .then( async (result) => {
                if(result === null || result === undefined) {
                  this.$notify('등록이 되지 않았습니다')
                } else {
                  let possess = possessStock[0]
                  let possessAmount = possess.AMOUNT
                  let possessPrice = possess.PRICE
                  if(isSell === true) {   // 매도
                    possess.AMOUNT -= amount
                  } else {                // 매수
                    let oldTotalPrice = possessPrice * possessAmount                        // 기존 총액
                    let newTotalPrice = price * amount                                      // 신규 총액
                    let newAmount = possessAmount + amount                                  // 신규 수량
                    possess.PRICE = Math.round((oldTotalPrice + newTotalPrice) / newAmount) // 신규 평균가
                    possess.AMOUNT = newAmount                                              // 신규 수량
                  }
                  await this.api.calcNextPrice(possess, price).then(async (result) => {
                    if(result === null || result === undefined) {
                      console.log('등록이 되지 않았습니다')
                    } else {
                      result.date = insertDate
                      await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
                      .then( async () => {
                        this.$notify('등록되었습니다')
                      }).catch( (err) => {
                        console.log(err)
                        console.log('등록이 되지 않았습니다')
                      })
                    }
                  })
                }
              })
            }
          }) 
          this.api.axiosGql('EXECUTE_FORCE', {type:'EXECUTE_FORCE', code: code, broker: broker})
          this.api.axiosGql('DELETE_STOCK_TRADE', {type:'DELETE_STOCK_TRADE', id:-1, code: code, broker: broker})
          .then( ()=> {
            this.click('SELECT_STOCK_TRADE')
          })
        }
      }
    },
    async updateTrade(code, broker, price, amount) {
      this.api.axiosGql('UPDATE_STOCK_TRADE', {type:'UPDATE_STOCK_TRADE', code:code, broker:broker, price:price, amount:amount})
      .then( async (result) => {
          let json = result.updateStockTrade
          if(json !== undefined && json.length !== 0) {
            this.$notify('매매가 수정이 완료되었습니다.')
            this.click('SELECT_STOCK_TRADE')
          }
        })
    }, 
    async remove(id) {
      // DELETE_STOCK_TRADE
      if(confirm(id +"를 삭제할까요?")) {
        await this.api.axiosGql('DELETE_STOCK_TRADE', {type:'DELETE_STOCK_TRADE', id: Number(id), code:'', broker:''})
        .then( async (result) => {
          let json = result.deleteStockTrade
          if(json !== undefined && json.length !== 0) {
            this.$notify('삭제되었습니다.')
            this.tradeSearch(this.tradePage, true, 5)
          }
        })
      }
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.tradePage === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.tradePage -= 1
      } else this.tradePage += 1
      this.tradeSearch(this.tradePage, true, 5)
    },

    async tradeSearch(page, isPage, cnt) {
      await this.api.axiosGql('SELECT_STOCK_TRADE', {type:'SELECT_STOCK_TRADE', page: page, cnt: cnt, code: this.$refs.code.value})
      .then( async (result) => {
        const json = result.data
        if(json === undefined || json.length === 0) {
            this.$notify('종목이 없습니다')
            if(isPage) this.tradePage -= 1
        } else {
          this.tradeList = Array()
          this.tradeList = json
          if(!isPage) this.tradePage = 1
        }
      })
    },

    async click(type) {
      if(type == 'amountKeyup') {
        const amount = Number(this.$refs.amount.value)
        if(amount > 1) this.$refs.broker.value = '한국투자증권'
        else if(amount === 1) this.$refs.broker.value = '삼성증권'
      } else if(type === 'codeKeyup') {
        let code = this.$refs.code.value
        if(code.length > 5) {
          await this.api.axiosGql('SELECT_STOCK_BY_CODE', {type:'SELECT_STOCK_BY_CODE', code: code, broker:''})
          .then( async (result) => {
            let json = result.data  
            if(json !== undefined && json.length !== 0) {
              this.$refs.name.value = json[0].NAME 
            }
          })
        } else if(code === '') this.$refs.name.value = ''
      } else if(type === 'INSERT_STOCK_TRADE') {
        let code = this.$refs.code.value
        let name = this.$refs.name.value
        let broker = this.$refs.broker.value
        let price = Number(this.$refs.price.value)
        let amount = Number(this.$refs.amount.value)
        let isSell = this.$refs.isSell.value === '1' ? true : false
        isSell = Boolean(isSell)

        if(code === '' || name === '' || broker === '' || price === '' || amount === '') this.$notify('필수값이 누락되었습니다.')
        else {
          await this.api.axiosGql(type, {type:type, code: code, name: name, broker: broker, price: price, amount: amount, isSell: isSell})
          .then( async (result) => {
            const json = result.data
            if(json !== undefined && json.length !== 0) {
              this.$notify('등록되었습니다.')
              this.$refs.code.value = ''
              this.$refs.name.value = ''
              this.$refs.price.value = ''
              this.$refs.amount.value = ''
              this.click('SELECT_STOCK_TRADE')
            }
          }).catch( (err) => {
            console.log(err)
            console.log('등록이 되지 않았습니다')
          })
        }
      } else if(type == 'SELECT_STOCK_TRADE') {
        this.tradeSearch(1, false, 5)
      } 
    },
    clickMenu() {
      const attr = document.getElementById("admin-menu").style.visibility;
      let menu = document.getElementById("nav-admin-icon");
      if(attr === 'visible') {
        menu.setAttribute("class", "");
        document.getElementById("admin-menu").style.visibility = 'hidden'
      } else {
        menu.setAttribute("class", "open");
        document.getElementById("admin-menu").style.visibility = 'visible'
      }
    }, 
  }
}
</script>

<style scoped>

.menu-admin {
  border-bottom: 1px dotted gray;
  padding: 5px;
  min-height: 25px;
}
#admin-menu {
  visibility: hidden;
  background: white;
  padding: 5px;;
  width: 450px;
  position:fixed;
  bottom:105px;
  right:30px;
  z-index: 100;
  margin-bottom: 20px;
  text-align: center;
  border: 1px dotted black;
  box-shadow: 3px 3px 3px  lightgray;
}
#admin-menu a, #admin-menu h3 {
  font-size: 0.9em;
  display: block;
  margin: 10px;
  color: black;
}
#nav-admin-icon {
  width: 50px;
  position:fixed;
  bottom:110px;
  right:210px;
  z-index: 999;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
#nav-admin-icon span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #ffa07a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
#nav-admin-icon span:nth-child(1) {
  top: 0px;
}
#nav-admin-icon span:nth-child(2) {
  top: 7px;
  background-color: transparent;
}
#nav-admin-icon span:nth-child(3) {
  top: 14px;
}
#nav-admin-icon span:nth-child(4) {
  top: 21px;
  background-color: transparent;
}
#nav-admin-icon span:nth-child(5) {
  top: 28px;
}
#nav-admin-icon span:nth-child(6) {
  background-color: white;
  top: 42px;
}
#nav-admin-icon.open span:nth-child(1) {
  top: 14px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
#nav-admin-icon.open span:nth-child(3) {
  opacity: 0;
  left: -60px;
}
#nav-admin-icon.open span:nth-child(5) {
  top: 14px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.input_width > input {
  max-width: fit-content;
  text-align: center;
}

.input_width {
  width: 13%;
}

tr:nth-child(even) {
  background-color: #f7f9fc;
}
</style>