<template>
  <div class="div_title2" >
    <span style="color: #ff7f50;">QA Pair</span> 
    <span class="div_title2">
      <div class="div_center">
        <input type="checkbox" v-model="useYn" id="useYn" style="vertical-align: middle;"  />
        <label for="useYn">useYn</label>
      </div>
      &nbsp;
      &nbsp;
      <input type="text" v-on:keyup.enter="click({type:'SELECT_QA'})" v-model="keyword" class="search-input" placeholder="검색어">
      &nbsp;
      <Button label="조회" @click="click({type:'SELECT_QA'})" raised size="small" class="qa-fair-btn2"/>
    </span>
  </div>
  <Divider/>
  <div class="card main">
    <div v-if="qaList === undefined || qaList.length === 0" class="no-result" > 조회를 해주세요.</div>
    <div v-else>
      <DataTable v-model:expandedRows="expandedRows" :value="qaList" dataKey="SEQ" @rowExpand="onRowExpand" stripedRows>
        <Column expander />
        <Column field="" header="날짜" >
          <template #body="slotProps">
            <span>{{ this.api.getDateByFormat(slotProps.data.REG_DATE, 'MM/DD') }}</span>
          </template>
        </Column>
        <Column field="TITLE" header="질문" />
        <Column field="CONTENT" header="답변" />
        <Column field="QUERY" header="QA" />
        <template #expansion="slotProps" >
          <div class="div_title2">
            <InputText v-model="query" placeholder="QA DATA SET" size="small" class="container" />&nbsp;
            <Button label="QA질문등록" raised size="small" @click="click({type:'UPDATE_NOTICE', seq:slotProps.data.SEQ})" class="qa-fair-btn" />&nbsp;
            <Button label="QA답변등록" raised size="small" @click="click({type:'INSERT_DATASET_QA', seq:slotProps.data.SEQ, useYn:slotProps.data.USE_YN})" class="qa-fair-btn" />&nbsp;
            <Button label="삭제" raised size="small" @click="click({type:'DELETE_NOTICE', seq:slotProps.data.SEQ})" class="qa-fair-btn2" />
          </div>
          <Divider />
          <div class="" style="background-color: #f9f9f9;">
            <table class="dynamic-table" style="margin-bottom:0px;">
              <tr style="height:100px;">
                <td>
                  <table class="order_sub_table" style="table-layout:fixed;">
                    <tbody style="text-align: left;">
                      <tr tr style="height:30px;">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>SEQ</div>
                            <div>{{slotProps.data.SEQ}}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>등록일</div>
                            <div>{{this.api.getDateByFormat(slotProps.data.REG_DATE, 'YYYY.MM.DD')}}</div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height:30px;">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>메일</div>
                            <div>{{slotProps.data.ID}}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>사용</div>
                            <div>
                              {{slotProps.data.USE_YN}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height:30px;" v-if="!(slotProps.data.QUERY === undefined || slotProps.data.QUERY === '')">
                        <td class="container_sub_style" colspan="2">
                          <div class="div_center order-div">
                            <div>QA질문</div>
                            <div>{{slotProps.data.QUERY}}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <DataTable v-if="useYn === true" :key="autoRagKey" :value="autoRagList" paginator :rows="10" editMode="cell" currentPageReportTemplate="{currentPage}page &nbsp; / &nbsp; 전체 {totalRecords}건" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"  >
                    <Column>
                      <template #body="{ data }">
                        <label style="color:#ff7f50;font-weight:bold;">QA답변 #{{ data.IDX }}</label><br><br>
                        <label>모범답안</label> : {{ data.GENERATION_GT }}<br><br>
                        <label>참조자료</label> : {{ data.RETRIEVAL_GT }}<br><br>
                        <label>추천링크</label> : {{ data.RECOMMEND_URL }}
                      </template>
                      <template #editor="{ data }">
                        <div class="div_title2">
                          <div class="container">
                            모범답안<Textarea v-model="data.GENERATION_GT" autofocus fluid style="width:100%;" autoResize /><br><br>
                            참조자료<Textarea v-model="data.RETRIEVAL_GT" autofocus fluid style="width:100%;" autoResize /><br><br>
                            추천링크<Textarea v-model="data.RECOMMEND_URL" autofocus fluid style="width:100%;" autoResize />
                          </div>
                          <span>
                            <Button text size="small" @click="click({type:'UPDATE_DATASET_QA', idx:data.IDX, generationGt:data.GENERATION_GT, retrievalGt:data.RETRIEVAL_GT, recommendUrl:data.RECOMMEND_URL })" icon="pi pi-pencil" severity="info" /><br>
                            <Button text size="small" @click="click({type:'DELETE_DATASET_QA', idx:data.IDX})" icon="pi pi-times" severity="danger"  /> 
                          </span>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </td>
              </tr>
            </table>
          </div>
        </template>
      </DataTable>
      <div class="div_center">
        <label @click="paging('prev')" class="link">이전</label>
        <span style="margin:20px">{{page}}</span>
        <label @click="paging('next')" class="link">다음</label>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from 'vue'

export default {
  setup() {
    return {
    }
  },
  data() {
    return {
      autoRagKey:-1,
      useYn:false,
      page:1,
      keyword:'',
      qaList:[],
      autoRagList:[],
      query:undefined,
      expandedRows:[]
    }
  },
  methods: {
    onRowExpand (event) {
      const json = event.data
      const seq = json.SEQ
      this.expandedRows = {[seq]:true}
      this.getDataset({seq:seq})
    },
    async click(param) {
      const type = param.type
      if(type === 'SELECT_QA') {
        this.emitter.emit('dialog', {flag:true, title:'검색중.'})
        await this.getQa({type:type, keyword:this.keyword, page:this.page, useYn:this.useYn })
        .then( async (res) => {
          if(res.status === 200) this.qaList = res.data
        })
        .catch( (err) => {
        console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'UPDATE_NOTICE') {
        if(this.query === undefined || this.query === '') alert('QUERY를 입력해주세요')
        else if(confirm("수정할까요?")) {
          await this.api.axiosGql(type, {type:type, seq:param.seq, useYn:true, query:this.query})
          .then( async (result) => {
            if(result !== null && result !== undefined) {
              if(Number(result.data.SEQ) === param.seq) {
                this.query = undefined
                this.click({type:'SELECT_QA'})
                alert('수정되었습니다.')
              }
            }
          })
          .catch( (err) => {
            console.log(err)
          })
        }
      } else if(type === 'INSERT_DATASET_QA') {
        const useYn = param.useYn
        if(useYn === false) alert('먼저 QA 질문을 등록해주세요')
        else if(this.query === undefined || this.query === '') alert('QUERY를 입력해주세요')
        else if(confirm("등록할까요?")) {
          await this.api.axiosGql(type, {type:type, seq:param.seq, useYn:true, generationGt:this.query})
          .then( async (result) => {
            if(result.status === 200) {
              this.query  = undefined
              this.qaList  = undefined
              this.click({type:'SELECT_QA'})
              this.getDataset({seq:Number(result.data.SEQ)})
              alert('등록되었습니다')
            } else alert('다시 시도해주세요')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요')
          })
        }
      } else if(type === 'DELETE_NOTICE') {
        if(confirm("삭제할까요?")) {
          await this.api.axiosGql(type, {type:type, seq:param.seq} )
          .then( async (result) => {
            if(Number(result.data) === param.seq) {
              alert('삭제되었습니다.')
            } else alert('다시 시도해주세요')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요')
          })
        }
      } else if(type === 'UPDATE_DATASET_QA') {
        await this.api.axiosGql(type, param )
        .then( async (result) => {
          if(result.status === 200) {
            this.autoRagList  = undefined
            this.getDataset({seq:Number(result.data)})
            alert('수정되었습니다')
          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요')
        })
      } else if(type === 'DELETE_DATASET_QA') {
        await this.api.axiosGql(type, param )
        .then( async (result) => {
          if(result.status === 200) {
            this.autoRagList  = undefined
            this.getDataset({seq:Number(result.data)})
            alert('삭제되었습니다')
          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요')
        })
      } 
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1

      await this.getQa({type:'SELECT_QA', keyword:this.keyword, page:this.page, useYn:this.useYn })
      .then( async (res) => {
        if(res.data.length === 0) {
        this.page -= 1
        alert('마지막 페이지입니다.')
        } else this.qaList = res.data
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    async getQa(param) {
      const type = param.type
      return this.api.axiosGql(type, param)
    },
    async getDataset(param) {
      const type = "SELECT_DATASET_QA"
      param.type = type
      await this.api.axiosGql(type, param)
      .then( async (result) => {
        this.autoRagList = result.data
        this.autoRagKey++
      })
      .catch( (err) => {
        console.log(err)
        alert('다시 시도해주세요')
      })
    }
  }
}
</script>
<style scoped>
.qa-fair-btn {
  min-width:100px;
}
.qa-fair-btn2 {
  min-width:50px;
}
</style>