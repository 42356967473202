<template>
  <Tabs :value="tIdx">
    <TabList>
      <Tab value=0>QA</Tab>
      <Tab value=1>Model</Tab>
      <Tab value=2>Recommend</Tab>
    </TabList>
    <TabPanels>
      <TabPanel value=0>
        <qafair />
      </TabPanel>
      <TabPanel value=1>
        <div class="div_title2" >
          <span style="color: #ff7f50;">Model List</span> 
          <span>
            <Button label="반영" @click="click({type:'EXECUTE_MODEL'})" raised size="small" /> &nbsp;
            <!-- <Button label="수정" @click="click({type:'UPDATE_MODEL', name:'model_list.txt'})" raised size="small" /> &nbsp; -->
            <Button label="조회" @click="click({type:'READ_MODEL'})" raised size="small" />
          </span>
        </div>
        <Divider/>
        <div class="card main">
          <Accordion v-model:value="expandedModelRows" multiple>
            <div v-if="modelList === undefined || modelList.length === 0" class="no-result" > 
              조회를 해주세요.
            </div>
            <AccordionPanel :value="String(index)" v-for="(model, index) in modelList" :key="index"  >
              <AccordionHeader :class="index%2===1 ? 'datatable-background' : ''" :style="model.useYn === '1' ? 'color:blue;' : ''"> 
                {{ model.id }} - {{ model.modelType }}
              </AccordionHeader>
              <AccordionContent>
                <table class="dynamic-table">
                  <tr style="height:100px;">
                    <td style="width:100%;">
                      <table class="order_sub_table" style="table-layout:fixed;" :id="`modelValue${index}`">
                        <tr v-for="(pair, index2) in groupedModel(model)" :key="index2" style="height:30px;">
                          <td class="container_sub_style">
                            <div class="div_center">
                              <div v-if="pair[0].key === 'file'" class="link" style="min-width:100px;font-weight: bold;" @click="this.openPolicyDialog({filePath:pair[0].value})">{{ pair[0].key }}</div>
                              <div v-else style="min-width:100px;font-weight: bold;">{{ pair[0].key }}</div>
                              <input class="normalInput" type="text" :value="pair[0].value">
                            </div>
                          </td>
                          <td class="container_sub_style " v-if="pair[1]">
                            <div class="div_center">
                              <div v-if="pair[1].key === 'file'" class="link" style="min-width:100px;font-weight: bold;" @click="this.openPolicyDialog({filePath:pair[1].value})">{{ pair[1].key }}</div>
                              <div v-else style="min-width:100px;font-weight: bold;">{{ pair[1].key }}</div>
                              <input class="normalInput" type="text" :value="pair[1].value">
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:100%;">
                      <Button severity="danger" rounded @click="click({type:'UPSERT_MODEL', title:model.id, useYn:0, noticeType:'chatbot', idx:index })" icon="pi pi-pause" aria-label="pause" v-if="model.useYn === '1'" />
                      <Button severity="danger" rounded @click="click({type:'UPSERT_MODEL', title:model.id, useYn:1, noticeType:'chatbot', idx:index })" icon="pi pi-play" aria-label="play" v-else />
                    </td>
                  </tr>
                </table>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
      </TabPanel>
      <TabPanel value=2>
        <recommend />
      </TabPanel>
    </TabPanels>
  </Tabs>
  <Dialog v-model:visible="policyVisible" modal header="Policy" style="width:100%;" >
    <div class="div_title2">
      <label>{{ filePath }}</label>
      <span>
        <InputText v-model="policyKeyword" placeholder="입력값" size="small" v-on:keyup.enter="click({type:'READ_FILE_ALL', filePath:this.filePath, page:1, cntPerPage:10, keyword:this.policyKeyword})"/>&nbsp;
        <Button label="입력" raised size="small" @click="click({type:'WRITE_FILE', filePath:this.filePath})" />&nbsp;
        <Button label="조회" raised size="small" @click="click({type:'READ_FILE_ALL', filePath:this.filePath, page:1, cntPerPage:10, keyword:this.policyKeyword})" />
      </span>
    </div>
    <div v-if="policyList === undefined || policyList.length === 0" class="no-result" > 
      <br>
      조회를 해주세요.
    </div>
    <DataTable :key="policyKey" v-else :value="policyList" paginator :rows="10" editMode="cell" currentPageReportTemplate="{currentPage}page &nbsp; / &nbsp; 전체 {totalRecords}건" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" >
      <Column >
        <template #body="{ data }">
          {{ data.index }}. {{ data.content }}
        </template>
        <template #editor="{ data }">
          <div class="div_title2">
            <Textarea v-model="data.content" autofocus fluid style="width:100%;" autoResize />
            <span>
              <Button text size="small" @click="click({type:'MODIFY_FILE_CONTENT', content: data.content, idx:data.index, filePath:this.filePath})" icon="pi pi-pencil" severity="info" /><br>
              <Button text size="small" @click="click({type:'REMOVE_FILE_CONTENT', content: data.content, idx:data.index, filePath:this.filePath})" icon="pi pi-times" severity="danger"  /> 
            </span>
          </div>
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>
<script>
import { ref } from 'vue'
import qafair from '@/components/langchain/qafair.vue'
import recommend from '@/components/langchain/recommend.vue'

export default {
  setup() {
    const policyVisible = ref(false) 
    const modelList = ref([])
    const expandedModelRows = ref([])
    const filePath = ref("")
    function openPolicyDialog (param) {
      policyVisible.value = true
      filePath.value = param.filePath
    }
    function closePolicyDialog () {
      policyVisible.value = false
    }
    function expandAll () {
      for (let i = 0; i < modelList.value.length; i++) {
        expandedModelRows.value.push(i+'')
      }
    }
    return {
      expandedModelRows, expandAll, modelList, policyVisible, openPolicyDialog, closePolicyDialog, filePath
    }
  },
  components: {
    qafair, recommend
  },
  mounted() {
  },
  data() { 
    return { 
      tIdx : '0',
      policyKey:-1,
      policyKeyword:undefined,
      policyPage:1,
      policyList:[],
      selectedModel:undefined
    }
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'SELECT_QA') {
        this.emitter.emit('dialog', {flag:true, title:'검색중.'})
        await this.getQa({type:type, keyword:this.keyword, page:this.page })
        .then( async (res) => {
          if(res.status === 200) this.qaList = res.data
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'EXECUTE_MODEL') {
        this.emitter.emit('dialog', {flag:true, title:'검색중.'})
        this.api.axiosGql(type, {type:type})
        .then( async (res) => {
          if(res.status === 200) alert('모델이 반영되었습니다')
          else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'READ_MODEL') {
        this.selectedModel = undefined
        this.expandedModelRows = []
        this.emitter.emit('dialog', {flag:true, title:'검색중.'})
        this.api.axiosGql(type, {type:type})
        .then( async (res) => {
          if(res.status === 200) {
            this.modelList = res.data
            for (let i = 0; i < this.modelList.length; i++) {
              const model = this.modelList[i]
              if(model.useYn === '1') {
                this.selectedModel = model.id
                return
              }
            }
          }
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'UPSERT_MODEL') {
        const useYn = param.useYn
        const id = param.title
        if(!(this.selectedModel === undefined || useYn !== 1 || this.selectedModel === id)) {
          alert('이미 구동 중인 모델이 있습니다1(' + this.selectedModel + ')')
          return
          // for (let i = 0; i < this.modelList.length; i++) {
          //   const model = this.modelList[i]
          //   if(model.id !== id && model.useYn === '1') {
          //     alert('이미 구동 중인 모델이 있습니다(' + model.id + ')')
          //     return
          //   }
          // }
        }
        this.emitter.emit('dialog', {flag:true, title:'등록중'})
        this.api.axiosGql(type, param)
        .then( async (res) => {
          if(res.status === 200) {
            this.modelList[param.idx].useYn =  useYn + ''
            // if(useYn === 0) alert(id + ' 모델이 중지되었습니다')
            // else alert(id + ' 모델이 구동되었습니다')
            this.updateModelFile()
          } else alert('다시 시도해주세요.')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'READ_FILE_ALL') {
        this.policyList = []
        this.emitter.emit('dialog', {flag:true, title:'읽어오는 중...'})
        this.api.axiosGql(type, param)
        .then( async (res) => {
          if(res.status === 200) {
            const tmeResult = res.data
            this.policyList = tmeResult
              .filter(item => item !== '' && item !== undefined)
              .map((item, index) => ({ index: index + 1, content: item }));

          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'WRITE_FILE') {
        if(this.policyKeyword === undefined || this.policyKeyword === '') {
          alert('정책을 입력해주세요')
        } else {
          const formData = new FormData()
          formData.append('type', type)
          formData.append('content', this.policyKeyword)
          formData.append('filePath', param.filePath)

          this.emitter.emit('dialog', {flag:true, title:'추가 중...'})
          this.api.axiosGql(type, formData)
          .then( async (res) => {
            if(res.status === 200) {
              this.policyKeyword = undefined
              this.policyList = undefined
              alert("추가되었습니다.")
            } else alert('다시 시도해주세요')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요.')
          })
          .finally( () => {
            this.emitter.emit('dialog', {flag:false})
            this.click({type:'READ_FILE_ALL', filePath:this.filePath, page:1, cntPerPage:10, keyword:undefined})
          })
        }
      } else if(type === 'READ_FILE_BY_PAGE') {
        this.emitter.emit('dialog', {flag:true, title:'읽어오는 중...'})
        this.api.axiosGql(type, param)
        .then( async (res) => {
          if(res.status === 200) {
            this.openPolicyDialog()
            const tmeResult = res.data.content
            this.policyList = tmeResult
              .filter(item => item !== '' && item !== undefined)
              .map((item) => ({ content: item }))
          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'MODIFY_FILE_CONTENT') {
        const filePath = param.filePath
        const modifiedContent = param.content
        const originalContent = this.policyList[param.idx - 1].content
        const formData = new FormData()
        formData.append('type', type)
        formData.append('modifiedContent', modifiedContent)
        formData.append('originalContent', originalContent)
        formData.append('filePath', filePath)

        this.emitter.emit('dialog', {flag:true, title:'수정하는 중...'})
        this.api.axiosGql(type, formData)
        .then( async (res) => {
          if(res.status === 200) {
            alert('수정되었습니다')
          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        })
        .finally( () => {
          this.emitter.emit('dialog', {flag:false})
          this.click({type:'READ_FILE_ALL', filePath:this.filePath, page:1, cntPerPage:10, keyword:undefined})
        })
      } else if(type === 'REMOVE_FILE_CONTENT') {
        if(confirm('삭제하시겠습니까?')) {
          const filePath = param.filePath
          const originalContent = this.policyList[param.idx - 1].content
          const formData = new FormData()
          formData.append('type', type)
          formData.append('originalContent', originalContent)
          formData.append('filePath', filePath)
  
          this.emitter.emit('dialog', {flag:true, title:'삭제하는 중...'})
          this.api.axiosGql(type, formData)
          .then( async (res) => {
            if(res.status === 200) {
              alert('삭제되었습니다')
            } else alert('다시 시도해주세요')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요.')
          })
          .finally( () => {
            this.emitter.emit('dialog', {flag:false})
            this.click({type:'READ_FILE_ALL', filePath:this.filePath, page:1, cntPerPage:10, keyword:undefined})
          })
        }
      }
    },
    async updateModelFile() {
      this.expandAll()
      const type = 'UPDATE_MODEL'
      await new Promise((resolve) => setTimeout(resolve, 1000))
      let modelList = ''
      for (let i = 0; i < this.modelList.length; i++) {
        const modelWrapper = document.getElementById('modelValue' + i)
        const modelPairList = modelWrapper.querySelectorAll('.div_center')
        let model ='{'
        for (let j = 0; j < modelPairList.length; j++) {
          const currentPair = modelPairList[j]
          const firstChild = currentPair.firstElementChild
          const secondChild = currentPair.children[1]

          const key = firstChild.innerHTML
          const val = secondChild.value
          if (!(val === '' || val === undefined)) {
            if (model !== '{') {
              model += ', '
            }
            model += `"${key}":"${val}"`
          }
          if(modelPairList.length-1 === j) modelList += model + '}'
        }
        if(!(this.selectedModel === undefined || model.indexOf('"useYn":"1"') === -1 || model.indexOf(this.selectedModel) !== -1)) {
          alert('이미 구동 중인 모델이 있습니다2(' + this.selectedModel + ')')
          this.click({type:'READ_MODEL'})
          return
        }
        if(this.modelList.length-1 === i) modelList += '\r\n'
        else modelList += '‱\r\n'
      }
      const formData = new FormData()
      formData.append('type', type)
      formData.append('modelList', modelList)
      this.emitter.emit('dialog', {flag:true, title:'파일 생성중.'})
      this.api.axiosGql(type, formData)
      .then( async (res) => {
        if(res.status === 200) {
          alert('수정되었습니다')
          this.click({type:'READ_MODEL'})
        } else alert('다시 시도해주세요')
      })
      .catch( (err) => {
        console.log(err)
        alert('다시 시도해주세요.')
      })
      .finally( () => {
        this.emitter.emit('dialog', {flag:false})
      })
    },

    groupedModel(model) {
      const entries = Object.entries(model)
      const grouped = []
      for (let i = 0; i < entries.length; i += 2) {
        grouped.push([
          { key: entries[i][0], value: entries[i][1] },
          i + 1 < entries.length ? { key: entries[i+1][0], value: entries[i+1][1] } : null
        ])
      }
      return grouped
    }
  },
}
</script>
<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>