<template>
  <table class="table" style="width:100%; ">
    <tr style="font-weight: bold;">
      <td>
        <span style="color: #ff7f50;">&#9654;</span> 
        <select style="text-align:center;margin-left:5px; padding:5px;" v-model="fulfillmentType" @change="changed({type:'resize'})"> 
          <option value="request">견적요청</option>
          <option value="mgr">담당자</option>
          <option value="warehouse">창고</option>
          <option value="estimate">케이스견적서</option>
          <option value="standard">표준견적서</option>
        </select>
      </td>
      <td style="text-align: right;">
        <input ref='fulfillmentAdd' id="fulfillmentAdd" type="checkbox" style="max-width: 15px;" @change="changed({type:'등록'})"/>
        <label for="fulfillmentAdd" style="margin-right:5px;">등록</label>
        <input ref='fulfillmentSearch' id="fulfillmentSearch" type="checkbox" style="max-width: 15px;" checked @change="changed({type:'조회'})"/>
        <label for="fulfillmentSearch" style="margin-right:5px;">조회</label>
        <input v-if="isAdd" style="max-width:50px;padding:5px;" type="button" v-on:click="click({type:'INSERT_WORK'})" value="등록" />
        <input v-else style="max-width:50px;padding:5px;" type="button" v-on:click="click({type:'SELECT_WORK'})" value="조회" />
      </td>
    </tr>
    <tr><td><br></td></tr>
    <tr v-if="!isAdd" class="fullfillment-keywords" >
      <td colspan="2" >
        <input ref='fulfillmentAnd' id="fulfillmentAnd" type="checkbox" style="max-width: 15px;" checked @change="changedKeyword({type:'and'})"/>
        <label for="fulfillmentAnd" style="margin-right:5px;">AND</label>
        <input ref='fulfillmentOr' id="fulfillmentOr" type="checkbox" style="max-width: 15px;" @change="changedKeyword({type:'or'})"/>
        <label for="fulfillmentOr" style="margin-right:5px;">OR</label>
        <input ref="tagKeyword" type="text" style="max-width:100px;padding:5px;text-align:center;" placeholder="키워드" v-on:keyup.enter="click({type:'addKeyword'})">
        &nbsp;&nbsp;<label @click="click({type:'addKeyword'})" class="keyword">추가</label>&nbsp;&nbsp;<label @click="click({type:'delKeyword'})" class="keyword">삭제</label>
      </td>
    </tr>
  </table>
  <div id="tagList">
    <label v-if="!(isAdd || resultList.length === 0)">{{ resultList.length }}건</label>
    <span v-for="(tag, index) in tagList" :key="index" class="tag" style="  display: inline-block;background-color: #fff7ed;padding: 5px 10px;margin-right: 5px;margin-bottom: 5px;border-radius: 16px;margin-left: 5px;cursor: pointer;border:1px dotted gray;">
      {{ tag }}
      <span class="close" @click="click({type:'remove', idx:index})" style="margin-left: 5px;cursor: pointer;font-size: 20px;color:red;">&times;</span>
    </span>
  </div>
  <div v-if="isAdd">
    <table v-if="fulfillmentType === 'request'" class="table fulfillment" style="width:100%; ">
      <tr style="height:40px;text-align: center;">
        <td style="width:25%">업무선택 *</td>
        <td style="width:25%">
          <select class="admin-contract" v-model="requestType" ref="requestType" @change="changed()"> 
            <option value="">선택</option>
            <option value="3PL">3PL(풀필먼트)</option>
            <option value="수출입">수출입</option>
            <option value="배송">배송</option>
            <option value="이사">해외이사</option>
          </select>
        </td>
        <td v-if="!(requestType === '' || requestType === '이사')" style="width:25%;text-align: center;">품목종류 *</td>
        <td v-if="!(requestType === '' || requestType === '이사')" style="width:25%"><input ref="goodsType" v-model="goodsType" class="admin-contract" type="text" placeholder="품목종류(정확하게 기재해주세요)"/></td>
      </tr>
      <tr v-if="requestType === '' && fulfillmentType !== 'mgr'" style="height:40px;text-align: center;">
        <td colspan="4" style="width:100%">원하시는 업무를 선택해주세요</td>
      </tr>
      <tr v-if="!(requestType === '' || requestType === '이사')" style="height:40px;text-align: center;">
        <td style="width:25%">HSCODE</td>
        <td style="width:25%">
          <input ref="hsCode" v-model="hsCode" class="admin-contract" type="text" placeholder="HSCODE" />
        </td>
        <td style="width:25%;text-align: center;">보관온도 *</td>
        <td style="width:25%">
          <select class="admin-contract" v-model="temperature" ref="temperature"> 
            <option value=''>선택</option>
            <option value="상온">상온</option>
            <option value="냉장">냉장</option>
            <option value="냉동">냉동</option>
            <option value="무관">무관</option>
          </select>
        </td>
      </tr>
      <tr v-if="requestType !== ''" style="height:40px;text-align: center;">
        <td style="width:25%">보관/출발국가 *</td>
        <td style="width:25%"><input ref="startCountry" v-model="startCountry" class="admin-contract" type="text" placeholder="보관/출발국가"/></td>
        <td style="width:25%;text-align: center;">배송도착국가 *</td>
        <td style="width:25%"><input ref="endCountry" v-model="endCountry" class="admin-contract" type="text" placeholder="목적지국가"/></td>
      </tr>

      <tr v-if="!(requestType === '' || requestType === '이사')" style="height:40px;text-align: center;">
        <td style="width:25%">전체무게(kg) *</td>
        <td style="width:25%"><input ref="totalWeight" v-model="totalWeight" class="admin-contract" type="number" placeholder="전체무게(kg)"/></td>
        <td style="width:25%">전체부피(mm) *</td>
        <td style="width:25%" ref="totalVolume">
          <input style="width:20%" v-model="totalVolumeWid" class="admin-volume-contract" type="number" placeholder="가로"/>
          <input style="width:20%" v-model="totalVolumeDep" class="admin-volume-contract" type="number" placeholder="세로"/>
          <input style="width:20%" v-model="totalVolumeHgt" class="admin-volume-contract" type="number" placeholder="높이"/>
        </td>
      </tr>
      <tr v-if="requestType === '3PL'" style="height:40px;text-align: center;">
        <td style="width:25%">개당무게(kg)</td>
        <td style="width:25%"><input v-model="unitWeight" class="admin-contract" type="number" placeholder="개당무게(kg)"/></td>
        <td style="width:25%">개당부피(mm)</td>
        <td style="width:25%">
          <input style="width:20%" v-model="unitVolumeWid" class="admin-volume-contract" type="number" placeholder="가로"/>
          <input style="width:20%" v-model="unitVolumeDep" class="admin-volume-contract" type="number" placeholder="세로"/>
          <input style="width:20%" v-model="unitVolumeHgt" class="admin-volume-contract" type="number" placeholder="높이"/>
        </td>
      </tr>
      <tr v-if="requestType === '3PL'" style="height:40px;text-align: center;">
        <td style="width:25%">예상월배송 *</td>
        <td style="width:25%">
          <input ref="orderCnt" v-model="orderCnt" class="fulfillment-value" type="number" placeholder="수량" style="margin-right:5px;"/>
          <select class="admin-contract" v-model="orderUnit" ref="company_select_box" style="max-width:fit-content"> 
            <option value=''>단위</option>
            <option value="개">개</option>
            <option value="box">박스</option>
            <option value="kg">kg</option>
            <option value="pallets">pallets</option>
            <option value="container">container</option>
          </select>
        </td>
        <td style="width:25%">예상월보관 *</td>
        <td style="width:25%">
          <input ref="storageCnt" v-model="storageCnt" class="fulfillment-value" type="number" placeholder="수량" style="max-width:fit-content;margin-right:5px;"/>
          <select class="admin-contract" v-model="storageUnit" ref="company_select_box" style="max-width:fit-content"> 
            <option value=''>단위</option>
            <option value="개">개</option>
            <option value="box">박스</option>
            <option value="kg">kg</option>
            <option value="pallets">pallets</option>
            <option value="container">container</option>
          </select>
        </td>
      </tr>
      <tr v-if="requestType !== ''" style="height:40px;text-align: center;">
        <td style="width:25%">이름</td>
        <td style="width:25%"><input v-model="orderName" ref="orderName" class="admin-contract" type="text" placeholder="문의자 이름" /></td>
        <td style="width:25%">SNS</td>
        <td style="width:25%">
          <input v-model="snsId" class="fulfillment-value" type="text" placeholder="SNS ID" style="margin-right:5px;"/>
          <select class="admin-contract" v-model="snsService" style="max-width:fit-content"> 
            <option value=''>선택</option>
            <option value="카카오톡">카톡</option>
            <option value="위챗">위챗</option>
            <option value="라인">라인</option>
            <option value="인스타">인스타</option>
          </select>
        </td>
      </tr>
      <tr v-if="requestType !== ''" style="height:40px;text-align: center;">
        <td style="width:25%">이메일</td>
        <td style="width:25%"><input v-model="mail" ref="mail" class="admin-contract" type="email" placeholder="문의자 이메일" /></td>
        <td style="width:25%">폰번호</td>
        <td style="width:25%"><input v-model="phone" ref="phone" class="admin-contract" type="tel" placeholder="문의자 폰번호" /></td>
      </tr>
      <tr v-if="requestType !== ''" style="height:40px;text-align: center;">
        <td colspan="4" style="width:100%">상세내용</td>
      </tr>
      <tr v-if="requestType !== ''" style="height:40px;text-align: center;">
        <td colspan="4" style="width:100%"><textarea v-model="desc" placeholder="상세설명" style="width:100%;background-color:transparent;" /></td>
      </tr>
    </table>
    <div v-else-if="fulfillmentType === 'estimate'" >
      <table class="table fulfillment" style="width:100%; ">
        <tr class="fullfillment-keywords">
          <td colspan="4">요청내용</td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">요청업체</td>
          <td style="width:25%"><input v-model='estimateSrcCompany' class="admin-contract" type="text" placeholder="견적을 제공한 업체명"/></td>
          <td style="width:25%;text-align: center;">담당자</td>
          <td style="width:25%"><input v-model="estimateSrcMgr" class="admin-contract" type="url" placeholder="견적 제공한 업체의 담당자명"/></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">연락처</td>
          <td style="width:25%"><input v-model="estimateSrcPhone" class="admin-contract" type="text" placeholder="담당자 연락처"/></td>
          <td style="width:25%;text-align: center;">이메일</td>
          <td style="width:25%"><input v-model='estimateSrcMail' class="admin-contract" type="url" placeholder="담당자 이메일"/></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td colspan="4" style="width:100%"><textarea v-model="estimateSrcDesc" placeholder="고객이 GEEK9 요청한 견적 내용" style="width:100%;background-color:transparent;" /></td>
        </tr>
      </table>
      <div v-if="estimateItems.length === 0" class="main container_no_login" >
        받은 견적이 없습니다.
      </div>
      <div v-else class="table fulfillment" style="width:100%;" v-for="(estimateItem, index) in estimateItems" :key="index" >
        <component :is="estimateItem" ref='key' class="main" :idx='index' @remove='estimateRemove' />
      </div>
      <div style="text-align:center;">
        <input @click="add_item()" style="max-width:120px" class="button_mod" type="button" value="&#43; 견적추가" />
      </div>
    </div>
    <table v-else-if="fulfillmentType === 'standard'" class="table fulfillment" style="width:100%; ">
      standard
    </table>
    <table v-else class="table fulfillment" style="width:100%;">
      <tr style="height:40px;text-align: center;">
        <td style="width:25%;text-align: center;">회사명</td>
        <td style="width:25%"><input ref="companyName" v-model="companyName" class="admin-contract" type="text" placeholder="회사명" /></td>
        <td style="width:25%;text-align: center;">회사연락처</td>
        <td style="width:25%"><input ref="companyPhone" v-model="companyPhone" class="admin-contract" type="tel" placeholder="회사연락처" /></td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td style="width:25%;text-align: center;">회사주소</td>
        <td style="width:25%"><input v-model="companyAddr" class="admin-contract" type="text" placeholder="회사주소"/></td>
        <td style="width:25%;text-align: center;">홈페이지</td>
        <td style="width:25%"><input v-model="companyUrl" class="admin-contract" type="url" placeholder="홈페이지"/></td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td style="width:25%;text-align: center;">담당자이름</td>
        <td style="width:25%"><input ref="mgrName" v-model="mgrName" class="admin-contract" type="text" placeholder="담당자이름" /></td>
        <td style="width:25%;text-align: center;">담당자직급</td>
        <td style="width:25%"><input ref="mgrRank" v-model="mgrRank" class="admin-contract" type="text" placeholder="담당자직급" /></td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td>담당자이메일</td>
        <td><input v-model="mgrMail" ref="mgrMail" class="admin-contract" type="email" placeholder="담당자 이메일" /></td>
        <td>담당자폰번호</td>
        <td><input ref="mgrPhone" v-model="mgrPhone" class="admin-contract" type="tel" placeholder="담당자 폰번호" /></td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td>주요업무</td>
        <td><input v-model="mgrRole" class="admin-contract" type="text" placeholder="담당자 주요역할"/></td>
        <td>담당국가</td>
        <td><input v-model="mgrCountry" class="admin-contract" type="text" placeholder="서비스국가"/></td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td colspan="4" style="width:100%">상세내용</td>
      </tr>
      <tr style="height:40px;text-align: center;">
        <td colspan="4" style="width:100%"><textarea v-model="mgrDesc" placeholder="상세설명" style="width:100%;background-color:transparent;" /></td>
      </tr>
    </table>
  </div>
  <div v-else>
    <div v-if="resultList.length === 0"  style="text-align:center;">
      <span>조회 내역이 없습니다</span>
    </div>
    <div v-else-if="fulfillmentType === 'request'" id="work-request-list" style="text-align:center">
      <div v-for="(result, index) in resultList" :key="index" >
        <table style="width:100%;">
          <tr>
            <button class="collapsible" style="padding:10px;" @click="collapse({id:'work-request-list',subIdx:index, idx:result.IDX})"> 
              <label style="color:black;cursor:pointer;">&nbsp;No.{{result.IDX}}.&nbsp; {{this.api.getDateByFormat(result.REG_DATE, 'YYYY.MM.DD')}} &nbsp; {{ result.GOODS_TYPE }} &nbsp; | &nbsp; {{result.TYPE}} 업무 &nbsp; | &nbsp; {{ result.TEMPERATURE }} <span style="float:right;"><label :style="{ color: getRequestColor(result.STATUS) }"><img v-if="!(result.FILE_NAME === undefined || result.FILE_NAME === ''  || result.FILE_NAME === null)" src="../../assets/email.png" style="width:15px;vertical-align: middle;"> {{ this.api.getRequestStatus(result.STATUS) }}</label></span></label>
            </button>
          </tr>
        </table>
        <workRequestc @taResize="taResize" :data-idx="result.IDX"  :json=result class="content" @search="search" :key="workRequestcKey"/>
      </div>
    </div>
    <div v-else-if="fulfillmentType === 'mgr'" id="work-mgr-list" style="text-align:center">
      <div v-for="(result, index) in resultList" :key="index" >
        <table style="width:100%;">
          <tr>
            <button class="collapsible" style="padding:10px;" @click="collapse({id:'work-mgr-list',subIdx:index, idx:result.IDX})"> 
              <label style="color:black;cursor:pointer;">&nbsp;No.{{result.IDX}}&nbsp; | &nbsp; {{ result.COMPANY_NAME }} &nbsp; | &nbsp; {{ result.MGR_ROLE }} &nbsp; | &nbsp; {{ result.COUNTRY }} <span style="float:right;">{{ result.MGR_PHONE }}</span></label>
            </button>
          </tr>
        </table>
        <workMgrc @taResize="taResize" :json=result class="content" @search="search" :key="workMgrcKey"/>
      </div>
    </div>
    <div v-else-if="fulfillmentType === 'warehouse'" id="work-warehouse-list" style="text-align:center">
      <div v-for="(result, index) in resultList" :key="index" >
        <table style="width:100%;">
          <tr>
            <button class="collapsible" style="padding:10px;" @click="collapse({id:'work-warehouse-list',subIdx:index, idx:result.IDX})"> 
              <label style="color:black;cursor:pointer;">&nbsp;No.{{result.IDX}}&nbsp; | &nbsp; {{ result.COUNTRY }} <span style="float:right;">{{ result.DESC }}</span></label>
            </button>
          </tr>
        </table>
        <workWarehousec :json=result class="content" @search="search" :key="workWarehousecKey"/>
      </div>
    </div>
    <div v-else-if="fulfillmentType === 'estimate'" id="work-estimate-list" style="text-align:center">
      <div v-for="(result, index) in resultList" :key="index" >
        <table style="width:100%;">
          <tr>
            <button class="collapsible" style="padding:10px;" @click="collapse({id:'work-estimate-list',subIdx:index, idx:result.IDX})"> 
              <label style="color:black;cursor:pointer;">&nbsp;No.{{result.IDX}}&nbsp; | &nbsp; {{ result.COMPANY_NAME }} &nbsp; | &nbsp; {{ result.MGR_ROLE }} &nbsp; | &nbsp; {{ result.COUNTRY }} <span style="float:right;">{{ result.MGR_PHONE }}</span></label>
            </button>
          </tr>
        </table>
        <!-- <workEstimatec @taResize="taResize" :json=result class="content" @search="search"/> -->
      </div>
    </div>
    <div v-else-if="fulfillmentType === 'standard'" id="work-standard-list" style="text-align:center">
      <div v-for="(result, index) in resultList" :key="index" >
        <table style="width:100%;">
          <tr>
            <button class="collapsible" style="padding:10px;" @click="collapse({id:'work-standard-list',subIdx:index, idx:result.IDX})"> 
              <label style="color:black;cursor:pointer;">&nbsp;No.{{result.IDX}}&nbsp; | &nbsp; {{ result.COMPANY_NAME }} &nbsp; | &nbsp; {{ result.MGR_ROLE }} &nbsp; | &nbsp; {{ result.COUNTRY }} <span style="float:right;">{{ result.MGR_PHONE }}</span></label>
            </button>
          </tr>
        </table>
        <!-- <workStandardc @taResize="taResize" :json=result class="content" @search="search"/> -->
      </div>
    </div>
    <div v-if="fulfillmentType === 'request' && this.statusList[0] !== -1" style="text-align:center">
      <br>
      <table style="width:100%;">
        <tr id="fulfillment-status">
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:0})" :style="{ 'background-color': getRequestColor(0) }">{{ statusList[0] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:1})" :style="{ 'background-color': getRequestColor(1) }">{{ statusList[1] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:2})" :style="{ 'background-color': getRequestColor(2), 'color':'black' }">{{ statusList[2] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:3})" :style="{ 'background-color': getRequestColor(3) }">{{ statusList[3] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:4})" :style="{ 'background-color': getRequestColor(4) }">{{ statusList[4] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:5})" :style="{ 'background-color': getRequestColor(5) }">{{ statusList[5] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:6})" :style="{ 'background-color': getRequestColor(6) }">{{ statusList[6] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:7})" :style="{ 'background-color': getRequestColor(7) }">{{ statusList[7] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:-1})" :style="{ 'background-color': getRequestColor(-1) }">{{ statusList[8] }}</td>
          <td @click="click({type:'SELECT_WORK_REQUEST_STATUS', status:-2})" :style="{ 'background-color': getRequestColor(-2) }">{{ statusList[9] }}</td>
        </tr>
      </table>
    </div>
    <br>
    <div v-if="resultList.length !== 0"  class="divider" />
    <br>
    <div v-if="resultList.length !== 0" style="text-align: center; margin-bottom: 20px;">
      <label @click="paging('prev')" class="link">
        이전
      </label>
      <span style="margin:20px">{{ page }}</span>
      <label @click="paging('next')" class="link">
        다음
      </label>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from 'vue'
import estimateItem from '@/components/items/estimateItem.vue'
import workMgrc from '@/components/content/workMgrContent.vue'
import workRequestc from '@/components/content/workRequestContent.vue'
import workWarehousec from '@/components/content/workWarehouseContent.vue'


// import workEstimatec from '@/components/content/workEstimateContent.vue'
// import workStandardc from '@/components/content/workStandardContent.vue'

export default {
  components: {
    workMgrc, workRequestc, estimateItem,
    item_tag: defineAsyncComponent(() => import("@/components/items/estimateItem.vue"))
  },
  setup() {
    let estimateItems = ref([])
    const add_item = () => {
      estimateItems.value.push('item_tag')
    }
    return {
      add_item, estimateItems, workWarehousec
    }
  },  
  mounted() {
  },
  data() { 
    return { 
      tagCount: 0,
      isAdd:false,
      isAnd:true,
      goodsType:'',
      hsCode:'',
      temperature:'',
      startCountry:'',
      endCountry:'',
      totalWeight:undefined,
      totalVolumeWid:undefined,
      totalVolumeDep:undefined,
      totalVolumeHgt:undefined,
      unitWeight:undefined,
      unitVolumeWid:undefined,
      unitVolumeDep:undefined,
      unitVolumeHgt:undefined,
      storageCnt:undefined,
      storageUnit:'',
      orderCnt:undefined,
      orderUnit:'',
      orderName:'',
      snsId:'',
      snsService:'',
      mail:'',
      phone:'',
      desc:'',
      companyName:'',
      companyPhone:'',
      companyAddr:'',
      companyUrl:'',
      mgrName:'',
      mgrMail:'',
      mgrPhone:'',
      mgrRole:'',
      mgrCountry:'',
      mgrRank:'',
      mgrDesc:'',
      requestType:'',
      fulfillmentType:'request',
      manager:'',
      tagList: [],
      resultList: [],
      statusList: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,],
      estimateSrcDesc:'',
      workRequestcKey:-1,
      workMgrcKey:-1,
      workWarehousecKey:-1,
      page:1
    }
  },
  methods: {
    estimateRemove(idx) {
      let receive_estimate_list = document.querySelectorAll(".receive-estimate")
      let receive_estimate_value_list = []
      for(var i=0; i<receive_estimate_list.length; i++) {
        if(i !== idx) {
          let input_list = receive_estimate_list[i].querySelectorAll(".admin-contract")

          const company = input_list[0].value
          const mgr = input_list[1].value
          const phone = input_list[2].value
          const mail = input_list[3].value
          const desc = input_list[4].value

          receive_estimate_value_list[receive_estimate_value_list.length] = {
            "company":company,
            "mgr":mgr,
            "phone":phone, 
            "mail":mail,
            "desc":desc
          }
        }
      }
      this.estimateItems.splice(idx, 1)
      receive_estimate_list = document.querySelectorAll(".receive-estimate")
      
      for(var j=0; j<receive_estimate_value_list.length; j++) {
        let input_list = receive_estimate_list[j].querySelectorAll(".admin-contract")
        let json = receive_estimate_value_list[j]
        input_list[0].value = json.company
        input_list[1].value = json.mgr
        input_list[2].value = json.phone
        input_list[3].value = json.mail
        input_list[4].value = json.desc
      }
    },
    taResize(param) {
      const cls = param.class
      let textareaList = document.getElementsByClassName(cls)
      for(var i=0; i<textareaList.length;i++) {
        textareaList[i].style.height = (textareaList[i].scrollHeight) + 'px'
      }
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: (response) => {
    //       this.$parent.$emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //     },
    //     fail: (err) => {
    //       this.$notify("다시 시도해주세요.")
    //       console.log(err);
    //     }
    //   });
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request();
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // },
    // logout() {
    //   window.Kakao.Auth.logout(() => {
    //     this.api.setCookies({login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
    //     this.tmp = 'not'
    //     this.$notify('로그아웃 되었습니다.');
    //   })
    // },
    async click(param) {
      let type = param.type
      if(type === 'SELECT_WORK_REQUEST_STATUS') {
        await this.api.axiosGql(type, param)
        .then( async(res) => {
          let result = res.data.getWorkRequestByStatus 
          if(result === undefined || result.length === 0) {
            alert('검색 결과가 없습니다.')
            this.resultList = []
          } else {
            this.tagList = []
            this.resultList = []
            this.resultList = result
          }
          this.workRequestcKey++
          this.workMgrcKey++
          this.workWarehousecKey++
        })
      } else if(type === 'addKeyword') {
        const keyword = this.$refs.tagKeyword.value
        if(keyword === '') alert('키워드를 입력해주세요')
        else {
          if(this.tagList.indexOf(keyword) === -1) {
            this.tagList.push(keyword)
            this.$refs.tagKeyword.value = ''
            this.autoSearch()
          } else alert('이미 추가된 키워드입니다')
        }
      } else if(type === 'delKeyword') {
        this.tagList = []
        this.autoSearch()
      } else if(type === 'remove') {
        const idx = param.idx
        this.tagList.splice(idx, 1)
        this.autoSearch()
      } else {
        if(this.isAdd === true && this.requestType === '' && this.fulfillmentType !== 'mgr' && this.fulfillmentType !== 'estimate') {
          alert('업무를 선택해주세요')
          return
        }
        type += '_' + String(this.fulfillmentType).toUpperCase()
        
        if(type === 'INSERT_WORK_REQUEST') {
          const formData = new FormData()
          formData.append("type", type)
          formData.append("requestType", this.requestType)
          formData.append("desc", this.desc)
          formData.append("id", this.api.getCookies('id'))
          formData.append("status", 0)
          formData.append("snsId", this.snsId)
          formData.append("snsService", this.snsService)
          formData.append("orderName", this.orderName)

          if(this.requestType === '3PL') {
            if(this.goodsType === undefined || this.goodsType === '' ) {
              this.$refs.goodsType.focus()
              alert('품목종류를 입력해주세요')
              return
            } else if(this.temperature === undefined || this.temperature === '' ) {
              this.$refs.temperature.focus()
              alert('보관온도를 선택해주세요')
              return
            } else if(this.startCountry === undefined || this.startCountry === '' ) {
              this.$refs.startCountry.focus()
              alert('보관/출발국가를 입력해주세요')
              return
            } else if(this.endCountry === undefined || this.endCountry === '' ) {
              this.$refs.endCountry.focus()
              alert('배송도착국가 국가를 입력해주세요')
              return
            } else if(this.totalWeight === undefined ) {
              this.$refs.totalWeight.focus()
              alert('전체무게를 입력해주세요')
              return
            } else if(this.totalVolumeWid === undefined || this.totalVolumeDep === undefined || this.totalVolumeHgt === undefined) {
              this.$refs.totalVolume.focus()
              alert('전체부피를 입력해주세요')
              return
            } else if(this.orderCnt === undefined ) {
              this.$refs.orderCnt.focus()
              alert('예상월배송 수량을 입력해주세요')
              return
            } else if(this.storageCnt === undefined ) {
              this.$refs.storageCnt.focus()
              alert('예상월보관 수량을 입력해주세요')
              return
            } else {
              formData.append("goodsType", this.goodsType)
              formData.append("hsCode", this.hsCode)
              formData.append("temperature", this.temperature)
              formData.append("startCountry", this.startCountry)
              formData.append("endCountry", this.endCountry)
              formData.append("totalWeight", this.totalWeight)
              formData.append("unitWeight", this.unitWeight)
              formData.append("totalVolumeWid", this.totalVolumeWid)
              formData.append("totalVolumeDep", this.totalVolumeDep)
              formData.append("totalVolumeHgt", this.totalVolumeHgt)
              formData.append("unitVolumeWid", this.unitVolumeWid)
              formData.append("unitVolumeDep", this.unitVolumeDep)
              formData.append("unitVolumeHgt", this.unitVolumeHgt)
              formData.append("storageCnt", this.storageCnt)
              formData.append("storageUnit", this.storageUnit)
              formData.append("orderCnt", this.orderCnt)
              formData.append("orderUnit", this.orderUnit)
              formData.append("mail", this.mail)
              formData.append("phone", this.phone)
            }
          } else if(this.requestType === '수출입' || this.requestType === '배송') {
            formData.append("goodsType", this.goodsType)
            formData.append("hsCode", this.hsCode)
            formData.append("temperature", this.temperature)
            formData.append("startCountry", this.startCountry)
            formData.append("endCountry", this.endCountry)
            formData.append("totalWeight", this.totalWeight)
            formData.append("totalVolumeWid", this.totalVolumeWid)
            formData.append("totalVolumeDep", this.totalVolumeDep)
            formData.append("totalVolumeHgt", this.totalVolumeHgt)
            formData.append("mail", this.mail)
            formData.append("phone", this.phone)
          } else if(this.requestType === '이사') {
            formData.append("startCountry", this.startCountry)
            formData.append("endCountry", this.endCountry)
            formData.append("mail", this.mail)
            formData.append("phone", this.phone)
          }
          if(confirm("등록할까요?")) {
            await this.api.axiosGql(type, formData)
            .then( async (res) => {
              if(res.data === null) this.$notify('다시 시도해주세요.')  
              else {
                this.page = 1
                this.search({type:'SELECT_WORK_REQUEST'})
                this.$notify('등록 되었습니다.')
              }  
            }).catch( (err) => {
              this.$notify('다시 시도해주세요.')
              console.log(err)
            })
          }
        } else if(type === 'INSERT_WORK_MGR') {

          if( (this.mgrPhone === undefined && this.mgrMail === undefined) || (this.mgrPhone === '' && this.mgrMail === '')) {
            alert('담당자 연락처를 입력해주세요')
            return
          } else if( this.companyName === undefined ||  this.companyName === '' ) {
            alert('회사명을 입력해주세요')
            return
          } else {
            const formData = new FormData()
            formData.append("id", this.api.getCookies('id'))
            formData.append("type", type)
            formData.append("desc", this.mgrDesc)
            formData.append("companyName", this.companyName)
            formData.append("companyPhone", this.companyPhone)
            formData.append("companyAddr", this.companyAddr)
            formData.append("companyUrl", this.companyUrl)
            formData.append("mgrName", this.mgrName)
            formData.append("mgrRank", this.mgrRank)
            formData.append("mgrMail", this.mgrMail)
            formData.append("mgrPhone", this.mgrPhone)
            formData.append("mgrRole", this.mgrRole)
            formData.append("country", this.mgrCountry)
            formData.append("userType", false)

            if(confirm("등록할까요?")) {
              await this.api.axiosGql(type, formData)
              .then( async (res) => {
                if(res.data === null) this.$notify('다시 시도해주세요.')  
                else {
                  this.$notify('등록 되었습니다.')
                  this.page = 1
                  this.search({type:'SELECT_WORK_MGR'})
                }
              }).catch( (err) => {
                this.$notify('다시 시도해주세요.')
                console.log(err)
              })
            }
          }
        } else if(type === 'INSERT_WORK_ESTIMATE') {
          
          let receive_estimate_list = document.querySelectorAll(".receive-estimate")
          let receive_estimate_value_list = []
          for(var i=0; i<receive_estimate_list.length; i++) {
            let input_list = receive_estimate_list[i].querySelectorAll(".admin-contract")

            const mgr = input_list[1].value
            const mail = input_list[3].value
            const desc = input_list[4].value
            const phone = input_list[2].value
            const company = input_list[0].value

            receive_estimate_value_list[receive_estimate_value_list.length] = {
              "company":company,
              "mgr":mgr,
              "phone":phone, 
              "mail":mail,
              "desc":desc
            }
          }
          if(receive_estimate_value_list.length > 0) {
            await this.api.list2String(receive_estimate_value_list)
            .then( async (tgtEstimate) => {
              if(confirm("등록할까요?")) {
                const formData = new FormData()
                formData.append("type", type)
                formData.append("id", this.api.getCookies('id'))
                formData.append("srcCompanyName", this.estimateSrcCompany)
                formData.append("srcMgrName", this.estimateSrcMgr)
                formData.append("srcMgrPhone", this.estimateSrcPhone)
                formData.append("srcMgrMail", this.estimateSrcMail)
                formData.append("srcDesc", this.estimateSrcDesc)
                formData.append("tgtEstimate", tgtEstimate)
                
                await this.api.axiosGql(type, formData)
                .then( async (res) => {
                  if(res.data === null) alert('다시 시도해주세요.')  
                  else {
                    alert('등록 되었습니다.')
                    this.page = 1
                    this.search({type:'SELECT_WORK_ESTIMATE'})
                  }
                }).catch( (err) => {
                  alert('다시 시도해주세요.')
                  console.log(err)
                })
              }
            })
          }
        } else if(type === 'SELECT_WORK_REQUEST') {
          this.page = 1
          this.search({type:type})
        } else if(type === 'SELECT_WORK_MGR') {
          this.page = 1
          this.search({type:type, pageType:undefined})
        } else if(type === 'SELECT_WORK_WAREHOUSE') {
          this.page = 1
          this.search({type:type, pageType:undefined})
        }
      }
    },
    async searchStatus() {
      const type = 'SELECT_WORK_REQUEST_STATUS_CNT'
      await this.api.axiosGql(type, {type:type})
      .then( async(res) => {
        const json = res.data
        if( !(json === undefined || json === '' || json === null) ) {
          this.statusList[0] = json.STEP0.length
          this.statusList[1] = json.STEP1.length
          this.statusList[2] = json.STEP2.length
          this.statusList[3] = json.STEP3.length
          this.statusList[4] = json.STEP4.length
          this.statusList[5] = json.STEP5.length
          this.statusList[6] = json.STEP6.length
          this.statusList[7] = json.STEP7.length
          this.statusList[8] = json.STEP_1.length
          this.statusList[9] = json.STEP_2.length
        }
      })
    },
    async autoSearch() {
      const type = 'SELECT_WORK_' + String(this.fulfillmentType).toUpperCase()
      this.page = 1
      this.search({type:type})
    },
    async search(param) {
      const type = param.type
      const pageType = param.pageType
      await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), page:this.page, isAnd:this.isAnd, keywordList:this.tagList})
      .then( async(res) => {
        let result = res.data
        if(result === undefined || result.length === 0) {
          if(pageType === 'prev' || pageType === 'next') {
            this.page -= 1
            alert('마지막 페이지입니다.')
            return
          } else {
            alert('검색 결과가 없습니다.')
            this.resultList = []
          }
        } else {
          this.collapseAll()
          this.resultList = []
          this.resultList = result
          if(type === 'SELECT_WORK_REQUEST') this.searchStatus()
        }
        this.workRequestcKey++
        this.workMgrcKey++
        this.workWarehousecKey++
      }).catch( (err) => {
        console.log(err)
        if(pageType === 'prev') this.page += 1
        else if(pageType === 'next') this.page -= 1
      })
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      
      let key = ''
      if(this.fulfillmentType === 'request') key = 'SELECT_WORK_REQUEST'
      else if(this.fulfillmentType === 'warehouse') key = 'SELECT_WORK_WAREHOUSE'
      else key = 'SELECT_WORK_MGR'
      await this.search({pageType:type, type:key})
    },
    collapse(param) {
      let id = param.id
      const sub_idx = param.subIdx
      const sub_menu_list = document.getElementById(id)
      let col_btn_list = sub_menu_list.getElementsByTagName("button")
      let col_con_list = sub_menu_list.getElementsByClassName("content")

      const btn = col_btn_list[sub_idx]
      const con = col_con_list[sub_idx]
      
      // open
      if(btn.getAttribute("class") === 'collapsible select' || btn.getAttribute("class") === 'collapsible select text-cut') {
        btn.setAttribute("class", "collapsible text-cut")
        btn.style.color = "black"
        btn.style.fontWeight = ""
        con.style.maxHeight = null
      } else {    // close
        this.collapseAll({id:id})
        btn.setAttribute("class", "collapsible select text-cut")
        btn.style.fontWeight = "bold"
        con.style.maxHeight = con.scrollHeight + "px"
        if(this.fulfillmentType === 'request') {
          let param = {json:{type:'SELECT_WORK_REQUEST_HISTORY', requestIdx:con.dataset.idx}, type:'SELECT_WORK_REQUEST_HISTORY'}
          this.emitter.emit('getHistory' + con.dataset.idx, param)
        }
      }


    },
    collapseAll(param) {
      let id = 'work-request-list'
      if(param !== undefined) id = param.id 
      const sub_menu_list = document.getElementById(id)
      if(sub_menu_list !== null && sub_menu_list !== undefined) {
        this.openedIdx = -1
        let col_btn_list = sub_menu_list.getElementsByTagName("button")
        let col_con_list = sub_menu_list.getElementsByClassName("content")
        
        for(var i = 0; i < col_btn_list.length; i++) {
          let colBtn = col_btn_list[i]
          if(colBtn !== undefined) {
            colBtn.setAttribute("class", "collapsible text-cut")
            colBtn.style.color = "black"
            colBtn.style.fontWeight = ""
          } 
          let tmpCon = col_con_list[i]
          if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
        }
      }
    },
    async changedKeyword(param) {
      let type
      if(param === undefined) type = undefined
      else type = param.type
      
      if(type === 'and') {
        if(this.$refs.fulfillmentOr.checked === true) {
          this.isAnd = true
          this.$refs.fulfillmentOr.checked = false
        }  
        if(this.$refs.fulfillmentAnd.checked === false) {
          this.isAnd = true
          this.$refs.fulfillmentAnd.checked = true
        }
        this.autoSearch()
      } else if(type === 'or') {
        if(this.$refs.fulfillmentAnd.checked === true) {
          this.isAnd = false
          this.$refs.fulfillmentAnd.checked = false
        }
        if(this.$refs.fulfillmentOr.checked === false) {
          this.$refs.fulfillmentOr.checked = true
        }
        this.autoSearch()
      }
    },
    async changed(param) {
      let type
      if(param === undefined) type = undefined
      else type = param.type
      
      this.clear()
      if(type === '등록') {
        if(this.$refs.fulfillmentSearch.checked === true) {
          this.isAdd = true
          this.$refs.fulfillmentSearch.checked = false
        }  
        if(this.$refs.fulfillmentAdd.checked === false) {
          this.isAdd = true
          this.$refs.fulfillmentAdd.checked = true
        }
      } else if(type === '조회') {
        if(this.$refs.fulfillmentAdd.checked === true) {
          this.isAdd = false
          this.$refs.fulfillmentAdd.checked = false
        }
        if(this.$refs.fulfillmentSearch.checked === false) this.$refs.fulfillmentSearch.checked = true
      } else if(type === 'resize') {
        if(this.fulfillmentType === 'estimate') {
          this.taResize({class:'estimateSrcDesc'})
          this.taResize({class:'estimateTgtDesc'})
        } else if(this.fulfillmentType === 'standard') {
          this.$emit("taResize", {class:'taStandard'})
        }
      }
    },
    clear() {
      this.goodsType = ''
      this.hsCode = ''
      this.temperature = ''
      this.startCountry = ''
      this.endCountry = ''
      this.totalWeight = undefined
      this.totalVolumeWid = undefined
      this.totalVolumeDep = undefined
      this.totalVolumeHgt = undefined
      this.unitWeight = undefined
      this.unitVolumeWid = undefined
      this.unitVolumeDep = undefined
      this.unitVolumeHgt = undefined
      this.storageCnt = undefined
      this.orderCnt = undefined
      this.mail = ''
      this.phone = ''
      this.desc = ''
      this.companyName = ''
      this.companyPhone = ''
      this.companyAddr = ''
      this.companyUrl = ''
      this.mgrName = ''
      this.mgrMail = ''
      this.mgrPhone = ''
      this.mgrRole = ''
      this.mgrCountry = ''
      this.mgrRank = ''
      this.mgrDesc = ''
      this.resultList = []
      this.tagList = []
      this.estimateItems = []
    },
    getRequestColor(status) {
      if(status === 0) return 'red'
      else if(status === 1) return 'orange'
      else if(status === 2) return 'yellow'
      else if(status === 3) return 'green'
      else if(status === 4) return 'blue'
      else if(status === 5) return 'brown'
      else if(status === 6) return 'purple'
      else return 'black'
    }
  }
}
</script>

<style scoped>
.admin-contract {
  width:100%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
.admin-volume-contract {
  padding-top:5px;
  padding-bottom:5px;
  margin-right:5px;
  text-align:center;
}
td > textarea {
  width: 100%;
  height: 200px;
  resize: none;
}
.fulfillment > tr > td:nth-child(odd) {
  font-weight: bolder;
}
.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.fulfillment-value {
  width:30%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
.fullfillment-keywords > td > label.keyword {
  text-align: center;
  background-color: aliceblue;
  height:30px;
  border:1px dotted lightsalmon;
  border-radius: 16px;
  padding:10px;
  color:#ff7f50;
  cursor: pointer;
}
#tagList {
  margin:10px;
  vertical-align: middle;
}
#fulfillment-status > td {
  color:white;
  height:30px;
  cursor: pointer;
}
</style>