<template>
<div id="workMgrC">
  <div style="width:100%; display:block;">
    <div class="card main" >
      <table class="table fulfillment-workmgr" style="width:100%;">
        <tr>
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;vertical-align: middle;"  >
            <select ref="mgrUserType" v-model="mgrUserType" class="orderc-input" style="float:left;" @change="changeStatus()">
              <option value='true'>수신동의</option>
              <option value='false'>수신거부</option>
            </select>
            담당자정보 
            <input type="button" value="삭제" style="float:right;max-width:50px;" @click="click({type:'DELETE_WORK_MGR'})"/>
            <input type="button" value="수정" style="float:right;max-width:50px;margin-right:5px;" @click="click({type:'UPDATE_WORK_MGR'})"/>
          </td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">회사명</td>
          <td style="width:25%"><input v-model="companyName" class="orderc-input" type="text" placeholder="회사명"/></td>
          <td style="width:25%;text-align: center;">회사연락처</td>
          <td style="width:25%"><input v-model="companyPhone" ref="companyPhone" class="orderc-input" type="text" placeholder="회사연락처" /></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">회사주소</td>
          <td style="width:25%"><input v-model="companyAddr" class="orderc-input" type="text" placeholder="회사주소"/></td>
          <td style="width:25%;text-align: center;">홈페이지</td>
          <td style="width:25%"><input v-model="companyUrl" class="orderc-input" type="text" placeholder="홈페이지 URL"/></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="width:25%;text-align: center;">담당자이름</td>
          <td style="width:25%"><input ref="mgrName" v-model="mgrName" class="orderc-input" type="text" placeholder="담당자 이름" /></td>
          <td style="width:25%;text-align: center;">담당자직급</td>
          <td style="width:25%"><input v-model="mgrRank" ref="mgrRank" class="orderc-input" type="text" placeholder="담당자 직급" /></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td>담당자이메일</td>
          <td><input ref="mgrMail" v-model="mgrMail" class="orderc-input" type="text" placeholder="담당자 이메일" /></td>
          <td>담당자폰번호</td>
          <td><input ref="mgrPhone" v-model="mgrPhone" class="orderc-input" type="text" placeholder="담당자 폰번호" /></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td>주요업무</td>
          <td><input v-model="mgrRole" class="orderc-input" type="text" placeholder="주요업무"/></td>
          <td>담당국가</td>
          <td><input v-model="country" class="orderc-input" type="text" placeholder="담당국가"/></td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;"  >
            상세내용 
          </td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td colspan="4">
            <textarea class="taMgr" placeholder="상세설명" v-model="desc" />
          </td>
        </tr>
      </table>
    </div> 
  </div>
</div>  
</template>
<script>
export default {
  mounted() {
    this.$emit("taResize", {class:'taMgr'})
    this.companyName = this.json.COMPANY_NAME
    this.desc = this.json.DESC
    this.companyPhone = this.json.COMPANY_PHONE
    this.country = this.json.COUNTRY
    this.mgrRole = this.json.MGR_ROLE
    this.mgrPhone = this.json.MGR_PHONE
    this.mgrMail = this.json.MGR_MAIL
    this.mgrRank = this.json.MGR_RANK
    this.mgrName = this.json.MGR_NAME
    this.companyUrl = this.json.COMPANY_URL
    this.companyAddr = this.json.COMPANY_ADDR
    this.mgrUserType = this.json.USER_TYPE
  },
  data() { 
    return { 
      companyName:'',
      desc:'',
      companyPhone:'',
      country:'',
      mgrRole:'',
      mgrPhone:'',
      mgrMail:'',
      mgrRank:'',
      mgrName:'',
      mgrUserType:'false',
      companyUrl:'',
      companyAddr:'',
    }
  },
  props: {
    json: {},
  },
  methods: {
    click(param) {
      const type = param.type
      const formData = new FormData()
      let workType = undefined
      if(type === 'UPDATE_WORK_MGR') {
        workType = '수정'
        const companyAddr = this.companyAddr
        const companyUrl = this.companyUrl
        const mgrRank = this.mgrRank
        const mgrName = this.mgrName
        const mgrPhone = this.mgrPhone
        const mgrMail = this.mgrMail
        const mgrUserType = this.mgrUserType
        const desc = this.desc
        const mgrRole = this.mgrRole
        const country = this.country
        const companyPhone= this.companyPhone
        const companyName = this.companyName
        
        formData.append('type', type)
        formData.append('idx', this.json.IDX)
        formData.append('companyAddr', companyAddr)
        formData.append('companyUrl', companyUrl)
        formData.append('mgrRank', mgrRank)
        formData.append('mgrName', mgrName)
        formData.append('mgrPhone', mgrPhone)
        formData.append('mgrMail', mgrMail)
        formData.append('desc', desc)
        formData.append('mgrRole', mgrRole)
        formData.append('country', country)
        formData.append('companyPhone', companyPhone)
        formData.append('companyName', companyName)
        formData.append('userType', mgrUserType)
        
      } else if(type === 'DELETE_WORK_MGR') {
        workType = '삭제'
        formData.append('type', type)
        formData.append('idx', this.json.IDX)
      }
      if(confirm(workType + "할까요?")) {
        this.execute({type:type, json:formData, workType:workType})
      }
    },
    async execute(param) {
      const type = param.type
      const json = param.json
      const workType = param.workType
      await this.api.axiosGql(type, json)
      .then( async (res) => {
        if(res.data === null) alert('다시 시도해주세요.')  
        else {
          this.$emit("search", {type:'SELECT_WORK_MGR', page:1})
          alert(workType + ' 되었습니다.')
        }
      }).catch( (err) => {
        alert('다시 시도해주세요.')
        console.log(err)
      })
    },
    async changeStatus() {
      const userType = this.mgrUserType === 'true' ? '수신동의' : '수신거부'
      const type = 'UPDATE_WORK_MGR_STATUS'
      if(confirm(userType + "로 변경 할까요?" ) === true) {
        console.log(Boolean(this.mgrUserType))
        await this.api.axiosGql(type, {type:type, idx:this.json.IDX, userType:Boolean(this.mgrUserType)})
        .then( async (res) => {
          if(res.data === null) alert('다시 시도해주세요.')  
          else {
            this.$emit("search", {type:'SELECT_WORK_MGR', page:1})
            alert('변경 되었습니다.')
          }
        }).catch( (err) => {
          alert('다시 시도해주세요.')
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped>
.admin-workmgr {
  width:100%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
.admin-volume-workmgr {
  padding-top:5px;
  padding-bottom:5px;
  margin-right:5px;
  text-align:center;
}
td > textarea {
  width: 100%;
  height: 400px;
  background-color:transparent;
  padding:5px;
}

.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.fulfillment-workmgr-value {
  width:30%;
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
}
td:nth-child(even){
  width:25%;
  border: 1px dotted #ffa07a; 
  padding-top:5px;
  padding-bottom:5px;
}
td:nth-child(odd){
  text-align: center;
  width:25%;
  border: 1px dotted #ffa07a; 
  font-weight:bold;
}
tr {
  height:30px;
}
table {
  border-collapse: collapse;
}
table tr:first-child td {
  border-top: 0;
}
table tr td:first-child {
  border-left: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:last-child {
  border-right: 0;
}
.orderc-input {
  text-align: center;
  max-width:fit-content;
  padding-top:5px;
  padding-bottom:5px;
}
</style>