<template>
  <div class="div_title">
    <span style="color: #ff7f50;">이력</span> 
    <span>
      <Button v-on:click="click({type:'출고'})" style="margin-right:5px;" label="출고" raised size="small" />
      <Button v-on:click="click({type:'입고'})" style="margin-right:5px;" label="입고" raised size="small" />
    </span>
  </div>
  <div class="card main" >
    <table>
      <tr>
        <td style="max-width: 70px; min-width: 70px;">
          <datepicker v-model="pickDate" />
        </td>
        <td style="width:100%">
          <input ref="conditionValue" type="text" style="max-width:30px; padding: 5px; float:right; margin-right:10px;" placeholder="수량" v-on:keyup.enter="click({type:'searchDetail'})">
          <select class="select-contract" style="margin-top: 0px;max-width: 120px;" v-model="item" > 
            <option value = "">선택</option>
            <option v-for="(item, index) in itemList" :value="item.NAME" v-bind:key="index">{{item.NAME}}</option>
          </select>
          <select class="select-contract" style="margin-top: 0px;max-width: 120px;" v-model="company" @change="change({type:'companySelected', })"> 
            <option value = "">선택</option>
            <option v-for="(company, index) in companyList" :value="company.NUM" v-bind:key="index">{{company.NAME}}</option>
          </select>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
  components: {
    datepicker
  },  
  mounted() {
    this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:undefined})
    .then( (res) => {
      const json = res.data
      if(!(json === null || json === undefined || json.length === 0)) {
        this.companyList = json
      } 
    })
  }, 
  data() { 
    return { 
      pickDate:ref(new Date()),
      companyList: Array(),
      itemList: Array(),
      item:'',
      company:'',
    }; 
  },
  methods: {
    change(param) {
      this.itemList = Array()
      const type = param.type
      if(type === 'companySelected') {
        this.item = ''
        this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:this.company, isEmpty:true})
        .then( (res) => {
          const json = res.data
          if(!(json === null || json === undefined || json.length === 0)) {
            this.itemList = json
          } 
        })

      }
    },    
    async click(param) {
      const type = param.type
      const dateF = new Date(this.pickDate)
      const date = new Date(dateF.getTime() - (dateF.getTimezoneOffset() * 60000)).toISOString().slice(0,10).replace(/-/g,"")
      const comNum = this.company
      const itemName = this.item
      const amount = Number(this.$refs.conditionValue.value)   
      if(comNum === '' || itemName === '' || amount === 0) {
        this.$notify('누락된 값이 있습니다')
      } else {
        const msg = itemName+" - "+amount+"개 " + type
        await this.api.axiosGql('INSERT_ORDER_HISTORY', {type:'INSERT_ORDER_HISTORY', id: comNum, uidBefore: '', uidAfter: '', content: msg, regDate:date })
        .then( async (res) => {
          if(res.data !== undefined) this.$notify('등록되었습니다.')
          else{this.$notify('뭔데?')}
        })
      }
    }
  }
}
</script>

<style scoped>
</style>