<template>
  <table class="table" style="width:100%; ">
    <tr style="font-weight: bold;">
      <td colspan="3"><span style="color: #ff7f50;">&#9654;</span> 명함</td>
      <td>
        <input style="max-width:70px;float:right;" type="button" v-on:click="click({type:'sendId'})" value="보내기" />
      </td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td style="width:15%">이메일</td>
      <td style="width:35%"><input v-model="mail" type="email" placeholder="받는사람 이메일"/></td>
      <td style="width:15%;text-align: center;">제목</td>
      <td style="width:35%"><input v-model="title" type="text" placeholder="메일 제목"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td colspan="4">
        <textarea style='width:100%;padding-top:10px;padding-bottom:10px;resize:none;height:300px;' v-model="content" placeholder="메일내용" />
      </td>
    </tr>
  </table>
</template>
<script>

export default {
  components: {
  },  
  mounted() {
  },
  data() { 
    return { 
      content:`안녕하세요!
금일 통화한 GEEK9 배성호 팀장입니다.

진행가능여부와 예상견적을 드리기 위해
아래의 내용을 요청드립니다!

1. 전체 품목 리스트
2. 품목 별 개당 수량과 무게, 부피
3. 품목 별 HSCODE
4. 대량인 경우 전체 무게와 부피
5. 출발지와 도착지(정확한 국가와 상세 주소)
6. 예상월보관수량
7. 예상월배송건수
8. 보관온도(상온/냉장/냉동)
9. 필요업무(3PL/배송/수출/수입 등)

위의 정보 알려주시면 다시 연락드리겠습니다.
협의/미팅 필요하시면 요청해주십시요!

감사합니다
`,
      title:'3PL 업무와 물류 업무 관련 안내 메일입니다',
      mail:''

    }
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'sendId') {
        const formData = new FormData()
        formData.append('type', type)
        formData.append('mail', this.mail)
        formData.append('title', this.title)
        formData.append('content', this.content)
        this.api.axiosGql(type, formData)
        alert('메일을 보냈습니다.')
      }
    },
  }
}
</script>