<template>
  <table class="table" >
    <tr>
      <td class="padding-left">
        <select ref="todoStatus" class="select" style="padding:5px;" @change="click({type:'SELECT_TODO'})">
          <option v-for="(opt, index) in optList" :value="opt" v-bind:key="index">{{opt}}</option>
        </select>
      </td>          
      <td style="width:100%;" class="padding-left">
        <textarea ref='todoContent' type="text" placeholder="내용 / 검색어" style="padding:5px;text-align:center;width:100%;resize:none;"/>
      </td> 
      <td class="padding-left">
      </td>
      <td class="padding-left">
        <input @click="click({type:'INSERT_TODO'})" type="button" value="등록" style="padding:5px;"/>
      </td>
      <td class="padding-left">
        <input @click="click({type:'SELECT_TODO'})" type="button" value="조회" style="padding:5px;"/>
      </td>
    </tr>
  </table>
  <br>
  <table v-if="todoList.length === 0" class="table" style="width:100%;">
    <tr>
      <td style="text-align:center;" colspan="5">
        조회내역이 없습니다
      </td>
    </tr>
  </table>
  <table v-else v-for="(todo, index) in todoList" v-bind:key="index" class="table">
    <tr>
      <td colspan="4">
        <div class="divider" />
      </td>
    </tr>
    <tr class="todo-keywords" >
      <td colspan="2" rowspan="2" style="text-align: center;">{{ this.api.getDateByFormat(todo.REG_DATE, 'YYYY.MM.DD') }}<br><br>
        <select v-model="status[index]" id="select" @change="this.click({type:'UPDATE_TODO', idx:index})">
          <option v-for="(opt, index2) in optList" :value="opt" v-bind:key="index2">{{opt}}</option>
        </select>
      </td>
      <td style="width:100%; text-align: center;" class="padding-left" rowspan="2">
        <textarea class="todo" type="text" @input="autoResize(index)" :value="todo.CONTENT" />
      </td>
      <td class="padding-left">
        <label class="keyword" @click="click({type:'UPDATE_TODO', idx:index})" type="button" >수정</label>
      </td>
    </tr>
    <tr class="todo-keywords" >
      <td class="padding-left">
        <label class="keyword" @click="click({type:'DELETE_TODO', idx:index})" type="button" >삭제</label>
      </td>
    </tr>
  </table>
</template>
<script>

export default {
  components: {
  },  
  mounted() {
  },
  data() { 
    return {
      optList:['전체', '작업중', '완료', '취소', '보류', '메모'],
      todoList:[],
      status:[]
    }
  },
  methods: {
    autoResize(idx) {
      const textarea = document.getElementsByClassName("todo")
      textarea[idx].style.height = (textarea[idx].scrollHeight - 10) + 'px'
    },
    async click(param) {
      const type = param.type
      const idx = param.idx
      if(type === 'SELECT_TODO') {
        const status = this.$refs.todoStatus.value === '전체' ? undefined : this.$refs.todoStatus.value
        const content = this.$refs.todoContent.value === '' ? undefined : this.$refs.todoContent.value
        await this.api.axiosGql(type, {type:type, status:status, content:content})
        .then( async (result) => {
          if(result.data.length === 0) {
            alert('내역이 없습니다.')
            this.todoList = []
          } else {
            this.todoList = result.data
            for(let i=0; i<this.todoList.length; i++) {
              this.status[i] = this.todoList[i].STATUS
            }
          }
        })
        .catch( (err) => {
          console.log(err)
        })
      } else if(type === 'INSERT_TODO') {
        const content = this.$refs.todoContent.value
        let status = this.$refs.todoStatus.value
        if( content === '' ) {
          alert('필수 입력값이 누락되었습니다.')
          return
        } else if( status === this.optList[0] ) status = this.optList[1]
        
        const formData = new FormData()
        formData.append('content', content)
        formData.append('status', status)
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (result) => {
          if(result === null || result === undefined) alert('다시 시도해주세요.')
          else {
            this.$refs.todoContent.value = ''
            this.$refs.todoStatus.value = '전체'
            this.$notify('등록되었습니다.')
            this.click({type:'SELECT_TODO'})
          }
        }).catch((err) => console.log(err) )
      } else if(type === 'DELETE_TODO') {
        if(confirm('삭제할까요?') === true) {
          await this.api.axiosGql(type, {type:type, idx:this.todoList[idx].IDX})
          .then( async (result) => {
            if(result === null || result === undefined) alert('다시 시도해주세요.')
            else {
              this.$refs.todoContent.value = ''
              this.$refs.todoStatus.value = '전체'
              this.$notify('삭제되었습니다.')
              this.click({type:'SELECT_TODO'})
            }
          }).catch((err) => console.log(err) )
        }
      } else if(type === 'UPDATE_TODO') {
        if(confirm('수정할까요?') === true) {
          const textarea = document.getElementsByClassName("todo")[idx]
          const formData = new FormData()
          formData.append('content', textarea.value)
          formData.append('status', this.status[idx])
          formData.append('idx', this.todoList[idx].IDX)
          formData.append('type', type)
  
          await this.api.axiosGql(type, formData)
          .then( async (result) => {
            if(result === null || result === undefined) alert('다시 시도해주세요.')
            else {
              this.$refs.todoContent.value = ''
              this.$refs.todoStatus.value = '전체'
              this.$notify('수정되었습니다.')
              this.click({type:'SELECT_TODO'})
            }
          }).catch((err) => console.log(err) )
        }
      }
    }
  }
}
</script>

<style scoped>
.todo-keywords > td {
  min-width:50px;
}
.todo-keywords > td > label.keyword {
  text-align: center;
  color:red;
  cursor: pointer;
}
.todo-keywords > td > textarea {
  text-align:center;
  border:none;
  width:95%;
  padding:5px;
}
</style>